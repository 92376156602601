export function getCommonPinningStyles (column) {
    const isPinned = column.getIsPinned()
    const isLastLeftPinnedColumn =
      isPinned === 'left' && column.getIsLastColumn('left')
    const isFirstRightPinnedColumn =
      isPinned === 'right' && column.getIsFirstColumn('right')
  
    return {
      boxShadow: isLastLeftPinnedColumn
        ? '3px 0 6px -1px rgba(0, 0, 0, 0.1)'
        : isFirstRightPinnedColumn
          ? '-3px 0 6px -1px rgba(0, 0, 0, 0.1)'
          : undefined,
      left: isPinned === 'left' ? `${column.getStart('left')}px` : undefined,
      right: isPinned === 'right' ? `${column.getAfter('right')}px` : undefined,
      opacity: isPinned ? 0.95 : 1,
      position: isPinned ? 'sticky' : 'relative',
      width: column.getSize(),
      zIndex: isPinned ? 1 : 0,
      ...isLastLeftPinnedColumn ? {borderRight: "none"} : {}
    }
}

export function isNewTableEnabled() {
  return JSON.parse(localStorage.getItem("isNewTableEnabled"));
}