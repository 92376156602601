import { AUTOMATION_DAYS_OPTIONS, AUTOMATION_TIME_OPTIONS, BIDDING_STRATEGIES, CURRENCY_SYMBOL_MAP } from "./store/constant";
import {useContext} from 'react';
import { accountContext } from "./context/AccountContext";
import { MIN_MAX_BIDS, TARGET_TYPE_MAP } from "./store/constant";
import moment from 'moment-timezone';
import pako from 'pako';
import { BASE_APP_URL, getFormattedDate } from "./service";
import { jsPDF } from "jspdf";
import html2canvas from 'html2canvas';
import Swal from 'sweetalert2'
import { Num } from "./layout/Components/table_cells";
import { FILTER_OPERATORS, LABELS, RAW_FILTER_CONFIGS } from "./features/common/utils/rawFilters";
import { METRICS as AMC_METRICS } from "./features/amc_reports/constants";
import { createFailureReasonSummary } from "./layout/Components/BulkActions/utils";

// Remove this and adjust imports to envUtils.js
export const get_env = () => {
    if (process.env.REACT_APP_ADBREW_BREWING_STAGE !== undefined) {
        return "brewing"
    } else if (process.env.NODE_ENV === 'development') {
        return "development"
    } else if (process.env.REACT_APP_PRODUCTION_ENV === 'shared') {
        return "shared"
    } else {
        return "production"
    }
}

const getDisplayableTime = (utcTime, accountTz) => {
    if (!utcTime) return ""
    return moment.tz(utcTime, "YYYY-MM-DDTHH:mm:ss.S", "UTC").tz(accountTz).format("DD-MM-yyyy hh:mm A")
}

const getTimeDiff = (utcTime, accountTz) => {
    return Math.abs(moment.tz(utcTime, "YYYY-MM-DDTHH:mm:ss.S", "UTC").tz(accountTz).diff(moment().tz(accountTz), 'hours'))
}

export const avg = arr => arr.reduce( ( p, c ) => p + c, 0 ) / arr.length;

export function downloadPDF(divId, fName, elementIdsToHide=[]) {
    // Hide elements before exporting
    const originalDisplays = elementIdsToHide.map(id => {
        const element = document.getElementById(id);
        const originalDisplay = element.style.display;
        element.style.display = 'none';
        return { element, originalDisplay };
    });

    // export
    var w = document.getElementById(divId).offsetWidth;
    var h = document.getElementById(divId).offsetHeight;
    html2canvas(document.getElementById(divId), {dpi: 300, scrollY: -window.scrollY}).then(function(canvas) {
        var img = canvas.toDataURL("image/jpeg", 1);
        var doc = new jsPDF('P', 'pt', [w, h]);
        doc.addImage(img, 'JPEG', 0, 0, w, h);
        doc.save(`${fName}.pdf`);
    });

    // Show elements after exporting
    originalDisplays.forEach(({ element, originalDisplay }) => {
        element.style.display = originalDisplay;
    });
}

export function getAccountAge() {
    // Get pseudo "account age" in minutes. This would not be accurate because we are using [last_updated] and don't have field [created_at]
    const { selectedAccountDetails } = useContext(accountContext);
    return moment().diff(moment(selectedAccountDetails.last_updated), 'minutes')
}

export function getSupportedCampaignTypes(changeType) {
    switch (changeType) {
        case 'bidding_strategy':
            return ['sp'];
        case 'placement':
            return ['sp'];
        default:
            return ['sp', 'sb', 'sd'];
    }
}

export function getDefaultRuleset(type, name, changeType, automated=false) {
    let actions, conditions = [{
        "attribute": "orders",
        "operator": "eq",
        "value": "0"
    }];
    let ruleName = "Default Rule";
    if (type === 'campaign') {
        switch (changeType) {
            case 'bidding_strategy':
                actions = [{ type: "set_value", attribute: "recommended_bidding_strategy", value: '"legacyForSales"' }]
                break;
            case 'placement':
                actions = [{ type: "set_value", attribute: "pp_recommended_bid", value: "min(900,max(10, 2.5*{pp_bid}))" }]
                break;
            case 'budget':
                actions = [{ type: "set_value", attribute: "recommended_budget", value: "min(5*{budget},max(1*{budget}, 1.5*{budget}))" }]
                break;
            case 'state':
                actions = [{ type: "set_state", attribute: "recommended_state", value: "paused" }]
                break;
            case 'target_acos':
                actions = [{ type: "set_value", attribute: "recommended_target_acos", value: "0.75*{target_acos}" }]
                break;
        }
    } else if (type === 'sov') {
        conditions = [
            {
                "attribute": "avg_org_rank",
                "operator": "lte",
                "value": "4"
            }
        ]
        actions = [{ type: "set_value", attribute: "recommended_bid", value: "1.05*{current_bid}" }]
    } else if (type === 'target') {
        actions = [{ type: "categorize", value: "promote_target" }]
    } else if (type === 'negative') {
        ruleName = "Negate generic if no orders, high clicks"
        type = 'target'
        conditions = [
            {
                "attribute": "orders",
                "operator": "eq",
                "value": "0"
            },
            {
                "attribute": "clicks",
                "operator": "gt",
                "value": "500"
            },
            {
                "attribute": "search_term",
                "operator": "does_not_contain",
                "value": "brand_account"
            },
            {
                "attribute": "search_term",
                "operator": "does_not_contain",
                "value": "competitor_account"
            }];
        actions = [{ type: "categorize", value: "promote_target" }]
    } else {
        ruleName = "Increase bid if some orders, and reasonable ACOS"
        conditions = [
            {
                "attribute": "orders",
                "operator": "gt",
                "value": "5"
            },
            {
                "attribute": "acos",
                "operator": "lt",
                "value": "0.75*{target_acos}"
            }];
        actions = [{ type: "set_value", attribute: "recommended_bid", value: "1.05*{current_bid}" }]
    }
    return {
        active: true,
        name: name,
        type: type,
        automated: automated,
        automation_config: {
            frequency: "7",
            date: getFormattedDate(new Date()),
            monthly_date: '1',
            days: [AUTOMATION_DAYS_OPTIONS[Math.floor(Math.random() * 7)]['value']],
            time: AUTOMATION_TIME_OPTIONS[Math.floor(Math.random() * 24)]['value'],
            using_data_from: 14,
            exclude_days: 1
        },
        payload: JSON.stringify([{
            name: ruleName,
            conditions: conditions,
            actions: actions
        }]),
        change_type: changeType,
        revert_config: changeType == 'budget' || changeType == 'state' ? {
            active: false,
            revert_after_days: 1,
            hour: '00'
        } : null
    }
}

export async function encodedAndCompressString(s) {
    const compressed = await compressString(s);
    return encodeURIComponent(btoa(compressed));
}

export function getFilter(attribute, label, operator, value, valueLabel) {
    return { attribute, label, operator, value, valueLabel };
}

export async function compressString(s="") {
    const compressed = pako.deflate(s);
    return String.fromCharCode.apply(null, compressed);
}

export async function decodeAndDecompressString(encodedCompressedString) {
    const compressedBuffer = Uint8Array.from(
        atob(decodeURIComponent(encodedCompressedString)),
        (c) => c.charCodeAt(0)
    );

    const decompressed = decompressString(compressedBuffer);
    return decompressed;
}

export function decompressString(buffer) {
    const decompressed = pako.inflate(buffer, { to: 'string' });
    return decompressed;
}

function isNumeric(str) {
    if (typeof str === 'number') return true
    if (typeof str != "string") return false // we only process strings!  
    return !isNaN(str) && // use type coercion to parse the _entirety_ of the string (`parseFloat` alone does not do this)...
           !isNaN(parseFloat(str)) // ...and ensure strings of whitespace fail
  }

function round(num, precision=2) {
    const precisionFixerValue = Math.pow(10, precision)
    return Math.round((num + Number.EPSILON) * precisionFixerValue) / precisionFixerValue
}

function isAsin(str) {
    const pattern = /^(B[\dA-Z]{9}|\d{9}(X|\d))$/
    return pattern.test(str.toUpperCase())
}

function removeASINPrefix(inputString) {
    return inputString.replace("ASIN: ", "");
}

function getValueType(v, changeType, rulesetType) {
    if (isNumeric(v)) {
        return {"type": "absolute", "value": v}
    }
    if (v == 'enabled' || v == 'paused' || v == 'archived') {
        if (changeType === 'state' || rulesetType === 'sov') {
            return { "type": "text_dropdown", value: v }
        }
        return { "type": 'state', value: v }
    }
    if (v === '"legacyForSales"' || v === '"autoForSales"') {
        return { "type": 'text_dropdown', value: v }
    }
    if (v.startsWith("min")) {
        const minMaxRegExp = /min\((.*?),\s*max\((.*?),\s*(.*?)\)\)/g;
        const [minMaxVals] = [...v.matchAll(minMaxRegExp)]
        if (!minMaxVals || minMaxVals.length === 0) {
            // for cases with min value is not set, to ensure code is backward compatible
            // before we introduced min bid, we only had max bid and we stored it as min(actual_value, max_value_threshold)
            // after we introduced min bid, we store it as: min(max_value_threshold, max(min_value_threshold, actual_value))
            const regexp = /min\((.*?),\s*(.*?)\)/g;
            const [array] = [...v.matchAll(regexp)];
            return {
                "type": "derived",
                "value": getValueType(array[1]),
                "maxValue": getValueType(array[2])
            }
        } else {
            return {
                "type": "derived",
                "maxValue": getValueType(minMaxVals[1]),
                "minValue": getValueType(minMaxVals[2]),
                "value": getValueType(minMaxVals[3]),
            }
        }
        
    }
    if (v.startsWith("brand") || v.startsWith("competitor") || v.startsWith("absolute")) {
        return {"type": "absolute", value: 1}
    }
    const regexp = /([0-9.]*?)\*?\{(.*?)\}/g;
    const [array] = [...v.matchAll(regexp)];
    return {
        "type": "derived",
        "value": {
            "multiplier": isNumeric(array[1]) ? array[1] : 1,
            "var": array[2]
        }
    }
}

function resolveValue({type, value}) {
    let dropdownValue = '', v = '';
    if (type === "absolute") {
        dropdownValue = "absolute";
        v = value;
    } else if (type === "text_dropdown") {
        dropdownValue = value;
        v = value;
    } else if (value?.var) {
        dropdownValue = value.var;
        v = value.multiplier;
    } else if (value?.type) {
        const r = resolveValue(value);
        dropdownValue = r.dropdownValue;
        v = r.v;
    }
    return {
        dropdownValue, v
    }
}

function capitalize(s) {
    return s.charAt(0).toUpperCase() + s.slice(1)
}

function pluralize(count, s) {
    return `${s}${(count || 0) != 1 ? 's' : ''}`
}

function isValidRulesetId(ruleset_id) {
    return String(ruleset_id || "").length > 2
}

const customCampaignDropdownFilter = (option, text) => {
    try {
        const labelText = option.label.props.children[1]
        if (labelText.toLowerCase().includes(text.toLowerCase())) {
            return true
        }
        return false;
    } catch (e) {
        return false;
    }
    
}

function getTargetDescription({type, target, showMatchType=true}) {
    if (type === 'keywords') {
        return `${target['text']}${showMatchType ? ` (${target['match_type']})` : ''}`
    } else {
        return `${(target['resolved_target'] || {})['value'] || target['text']}`
    }
}

// This function gets the asin and text of a target. 
const getTargetText = (target) => {
    const description = target?.text || '' //getTargetDescription({ type: tgt.type, target: tgt, showMatchType: false })
    const asin = target.resolved_target.value
    return {asin, description}
}

function getTargetType({ type, target }) {
    if (type === 'keywords' && target) {
        return `${capitalize(target.match_type || '')} Keyword`
    } else if ((type == 'target' || type == 'targets') && target) {
        console.log({target})
        const { type: target_type, sub_type } = target.resolved_target || {};
        const typeToText = {
            'category': 'Product Category',
            'asin': 'Product',
            'auto': 'Auto',
            'brand': 'Brand',
            'views': 'Views',
            'audience': 'Audience',
            'purchases': 'Purchases',
            'asin_expanded': 'Expanded Product'
        }
        let k = target_type
        if (sub_type) {
            k = `${target_type}_${sub_type}`
        }
        return `${typeToText[k] || "Other"}`
    }
}

const getTaskTargetType = ({task, type, target}) => {
    if (!task) return ""
    if (type === 'keywords' && target) {
        return `${capitalize(target.match_type)} Keyword`
    } else if (type == 'targets' && target) {
        const {type: target_type} = target.resolved_target || {};
        return `${target_type === 'category' ? 'Product Category' : 'Product'}`
    } else if (task.target_type) {
        return TARGET_TYPE_MAP[task.target_type]
    }
}

const getTargetName = ({ type, target, keyword, query='', category, task }) => {
    if (!task) return ""
    const { task_type, target_type } = task;
    if (task_type === 'bid_optimization') {
        const targetName = (type === 'keywords') ? target.text : ((target.resolved_target || {}).value || target.text)
        return targetName
    } else if (target_type !== undefined) {
        let displayableTarget;
        if (target_type.includes("keyword")) {
            displayableTarget = keyword
        } else if (target_type === 'product' || target_type === 'negative_product') {
            displayableTarget = query.toUpperCase()
        } else if (target_type === 'product_category') {
            if (category)
                displayableTarget = category.name
            else
                displayableTarget = query.toUpperCase()
        }

        return displayableTarget
    }
    else return ""
}

const getUndoDescription = ({state_last_updated, revert_status, reasons, accountTz}) => {
    if (!state_last_updated) return ""

    if (revert_status == 'success'){
        return `Reverted ${moment.tz(state_last_updated, "YYYY-MM-DDTHH:mm:ss.S", "UTC").tz(accountTz).fromNow()}`
    }
    return `Undo failed: ${createFailureReasonSummary(JSON.parse(reasons))}`
}

const getChangeDescriptionAndTargetName = ({ _id, actor, reverted_time_utc, state_last_updated, task, type, rule_type, target = {}, current_bid, selected_bid, keyword, query, category, state, recommended_state, selected_state, change_type, budget, selected_budget, bidding_strategy, selected_bidding_strategy, pp_bid, tos_selected_bid, ros_selected_bid, pp_selected_bid, tos_bid, ros_bid, revert_time_utc, revert_status, target_acos, selected_target_acos, reasons }, onClick, accountTz) => {
    if (!task) return {changeDescription: "",targetName: ""}
    const {task_type, target_type} = task;
    if (task_type === 'bid_optimization') {
        let descriptionText;
        if (selected_bid) {
            descriptionText = `Bid ${selected_bid > current_bid ? "increased" : "decreased"} from ${Math.round(current_bid * 100) / 100} to ${Math.round(selected_bid * 100) / 100}`
        } else if (state != recommended_state){
            descriptionText = "Paused"
        }
        
        const description = <div style={{width: "100%", paddingTop: state_last_updated ? "15px" : "0px"}}>
            {descriptionText}
            {actor == "USER" && revert_status && <p className="text-muted">{getUndoDescription({ state_last_updated: state_last_updated || reverted_time_utc, revert_status, reasons, accountTz })}</p>}
        </div> 
        const targetName = getTargetName({type, target, keyword, query, category, task})
        return {
            changeDescription: description,
            targetName: <div><a className="inline-anchor" onClick={() => { onClick({ target, _id }); }}>{targetName}</a></div>,
            targetNameText: targetName,
            descriptionText: descriptionText
        }
    } else if (task_type === 'campaign_management') {
        let descriptionText;

        if (change_type == 'state') {
            descriptionText = `State changed from ${state} to ${selected_state}`
        } else if (change_type == 'target_acos') {
            descriptionText = `Target ACOS changed from ${target_acos}% to ${selected_target_acos}%`
        } else if (change_type == 'budget') {
            descriptionText = `Budget changed from ${budget} to ${selected_budget}`
        }
        else if (change_type == 'bidding_strategy') {
            descriptionText = `Bidding strategy changed from ${BIDDING_STRATEGIES[bidding_strategy]} to ${BIDDING_STRATEGIES[selected_bidding_strategy]}`
        }
        else if (change_type == 'placement') {
            if (pp_selected_bid) {
                descriptionText = `Product pages placement bid changed from ${pp_bid}% to ${pp_selected_bid}%`
            } else if (tos_selected_bid) {
                descriptionText = `Top of search placement bid changed from ${tos_bid}% to ${tos_selected_bid}%`
            } else {
                descriptionText = `Rest of search placement bid changed from ${ros_bid}% to ${ros_selected_bid}%`
            }
        }
        const description = <div style={{ width: "100%", paddingTop: revert_time_utc ? "15px" : "0px" }}>
            {descriptionText}
            {actor == "USER" && revert_status && <p className="text-muted">{getUndoDescription({ state_last_updated: state_last_updated || reverted_time_utc, revert_status, reasons, accountTz })}</p>}
            {actor == "SYSTEM" && revert_time_utc && (revert_status == 'created') && <p className="text-muted">Will be reverted on: {moment.tz(revert_time_utc, "YYYY-MM-DDTHH:mm:ss.S", "UTC").tz(accountTz).format("MM/DD/YYYY-HH a")}</p>}
            {actor == "SYSTEM" && revert_time_utc && revert_status == 'success' && <p className="text-muted">Reverted {moment.tz(revert_time_utc, "YYYY-MM-DDTHH:mm:ss.S", "UTC").tz(accountTz).fromNow()}</p>}
            {actor == "SYSTEM" && revert_time_utc && revert_status == 'scheduled' && <p className="text-muted">Revert scheduled. It may take upto 60 minutes to reflect the changes.</p>}
        </div>
        return {
            changeDescription: description,
            targetName: <div><a className="inline-anchor" target="_blank" href={`/campaigns/${task.campaign_type}/${task.campaign_id}`}>{task.campaign_name}</a></div>,
            targetNameText: task.campaign_name,
            descriptionText: descriptionText
        }
    } else if (target_type !== undefined) {
        const displayableTarget = getTargetName({ type, target, keyword, query, category, task })
        let descriptionText = '';
        let targetName = ''
        if (rule_type === 'negate_target') {
            descriptionText = 'Negated';
            targetName = displayableTarget;
        } else {
            descriptionText = 'Promoted';
            targetName = <div><a className="inline-anchor" onClick={() => { onClick({ _id, target }); }}>{displayableTarget}</a></div>;
        }
        const description = <div style={{width: "100%", paddingTop: state_last_updated ? "15px" : "0px"}}>
            {descriptionText}
            {revert_status && <p className="text-muted">{getUndoDescription({ state_last_updated: state_last_updated || reverted_time_utc, revert_status, reasons, accountTz })}</p>}
        </div> 
        return { changeDescription: description, 
                 targetName,           
                 targetNameText: targetName,
                 descriptionText: descriptionText };
    }
    else return {changeDescription: "", targetName: "",targetNameText: "",descriptionText: ""}
}

function prefixCurrency(value, currencyCode) {
    if (!currencyCode) {
        const {selectedAccountDetails} = useContext(accountContext);
        currencyCode = selectedAccountDetails?.currency_code

        if (value === undefined) return CURRENCY_SYMBOL_MAP[currencyCode];
    }
    
    return `${CURRENCY_SYMBOL_MAP[currencyCode]}${value}`
}

function removeDuplicates(array) {
    return array.filter((ele, index) => (array.indexOf(ele) === index))
}

function getSearchTermKey({query, campaignId, adGroupId, keyword, target, campaign_type}) {
    return JSON.stringify({
        query,
        campaignId,
        adGroupId,
        keywordId: keyword.id,
        targetId: target.id,
        campaignType: campaign_type
    })
}

function getBidConstraints(campaignType="sp") {
    const {selectedAccountDetails} = useContext(accountContext);
    const bid_constraint = MIN_MAX_BIDS[campaignType][selectedAccountDetails.country_code]
    return [bid_constraint[0]*2, bid_constraint[1]]
}

function userInOrg(user, org_id) {
    const idx = user.orgs.findIndex(({id}) => id === org_id);
    const usr = user.orgs[idx];
    return usr
}

function convertArrayOfObjectsToCSV({array, specialKeys}) {

	let result;
	const columnDelimiter = ',';
	const lineDelimiter = '\n';
	const keys = specialKeys ? specialKeys : Object.keys(array[0]);

	result = '';
	result += keys.join(columnDelimiter);
	result += lineDelimiter;

	array.forEach(item => {
		let ctr = 0;
		keys.forEach(key => {
			if (ctr > 0) result += columnDelimiter;
			result += item[key];
			ctr++;
		});
		result += lineDelimiter;
	});
	return result;

}


export function getSpApiAuthUrl({baseUrl, appId, state}) {
    const redirect_uri = `${BASE_APP_URL.replace("http://", "https://").replace("localhost:3000", "dev.app.adbrew.io")}/accounts_manager`
    return `${baseUrl}/apps/authorize/consent?application_id=${appId}&state=${state}&redirect_uri=${redirect_uri}`
}

function metricsForExport({metrics, retail_metrics=false, withChange=false, decimalToPercentage=false, advMetrics=true, optionalMetrics=[], prefix="", withSov=false}) {
    if (withChange) {
        let o = {
            impressions: metrics.impressions.current || 0,
            impressions_change_perc: metrics.impressions.change_perc ? round(metrics.impressions.change_perc) : "--",
            impressions_change: metrics.impressions.change ? metrics.impressions.change : "--",

            clicks: metrics.clicks.current || 0,
            clicks_change_perc: metrics.clicks.change_perc ? round(metrics.clicks.change_perc) : "--",
            clicks_change: metrics.clicks.change ? metrics.clicks.change : "--",

            units: metrics.orders.current || 0,
            units_change_perc: metrics.orders.change_perc ? round(metrics.orders.change_perc) : "--",
            units_change: metrics.orders.change ? metrics.orders.change : "--",

            orders: metrics.conversions.current || 0,
            orders_change_perc: metrics.conversions.change_perc ? round(metrics.conversions.change_perc) : "--",
            orders_change: metrics.conversions.change ? metrics.conversions.change : "--",

            sales: round(metrics.sales.current || 0),
            sales_change_perc: metrics.sales.change_perc ? round(metrics.sales.change_perc) : "--",
            sales_change: metrics.sales.change ? round(metrics.sales.change) : "--",

            spend: round(metrics.spend.current || 0),
            spend_change_perc: metrics.spend.change_perc ? round(metrics.spend.change_perc) : "--",
            spend_change: metrics.spend.change ? round(metrics.spend.change) : "--",

            acos: Num({ val: metrics.acos.current || 0, multiplier: decimalToPercentage ? 100 : 1 }),
            acos_change_perc: metrics.acos.change_perc ? round(metrics.acos.change_perc) : "--",
            acos_change: metrics.acos.change ? round(metrics.acos.change) : "--",

            roas: round(metrics.roas.current || 0),
            roas_change_perc: metrics.roas.change_perc ? round(metrics.roas.change_perc) : "--",
            roas_change: metrics.roas.change ? round(metrics.roas.change) : "--",

            cpc: round(metrics.cpc.current || 0),
            cpc_change_perc: metrics.cpc.change_perc ? round(metrics.cpc.change_perc) : "--",
            cpc_change: metrics.cpc.change ? round(metrics.cpc.change) : "--",

            ctr: round(metrics.ctr.current || 0),
            ctr_change_perc: metrics.ctr.change_perc ? round(metrics.ctr.change_perc) : "--",
            ctr_change: metrics.ctr.change ? round(metrics.ctr.change) : "--",

            conversion_rate: Num({ val: metrics.conversion_rate.current || 0, multiplier: decimalToPercentage ? 100 : 1 }),
            conversion_rate_change_perc: metrics.conversion_rate.change_perc ? round(metrics.conversion_rate.change_perc) : "--",
            conversion_rate_change: metrics.conversion_rate.change ? round(metrics.conversion_rate.change) : "--",
        };

        if(withSov){
            o = {
                ...o,
                organic_sov: Num({ val: metrics.organic_sov.current || 0 }),
                organic_sov_change_perc: metrics.organic_sov.change_perc ? round(metrics.organic_sov.change_perc) : "--",
                organic_sov_change: metrics.organic_sov.change ? round(metrics.organic_sov.change) : "--",

                sb_sov: Num({val: metrics.sb_sov.current || 0}),
                sb_sov_change_perc: metrics.sb_sov.change_perc ? round(metrics.sb_sov.change_perc) : "--",
                sb_sov_change: metrics.sb_sov.change ? round(metrics.sb_sov.change) : "--",
            
                sp_sov: Num({ val: metrics.sp_sov.current || 0 }),
                sp_sov_change_perc: metrics.sp_sov.change_perc ? round(metrics.sp_sov.change_perc) : "--",
                sp_sov_change: metrics.sp_sov.change ? round(metrics.sp_sov.change) : "--",
            
                sp_tos_sov: Num({ val: metrics.sp_tos_sov.current || 0 }),
                sp_tos_sov_change_perc: metrics.sp_tos_sov.change_perc ? round(metrics.sp_tos_sov.change_perc) : "--",
                sp_tos_sov_change: metrics.sp_tos_sov.change ? round(metrics.sp_tos_sov.change) : "--",
            
                total_sov: Num({ val: metrics.total_sov.current || 0 }),
                total_sov_change_perc: metrics.total_sov.change_perc ? round(metrics.total_sov.change_perc) : "--",
                total_sov_change: metrics.total_sov.change ? round(metrics.total_sov.change) : "--",
            }
        }

        return o
    }
    let o = {}

    if (advMetrics) {
        o = {
            ...o,
            impressions: metrics.impressions || 0,
            clicks: metrics.clicks || 0,
            units: metrics.orders || 0,
            orders: metrics.conversions || 0,
            sales: round(metrics.sales || 0),
            spend: round(metrics.spend || 0),
            acos: Num({val: metrics.acos || 0, multiplier: decimalToPercentage ? 100 : 1}),
            roas: round(metrics.roas || 0),
            cpc: round(metrics.cpc || 0),
            ctr: Num({val: metrics.ctr || 0, multiplier: decimalToPercentage ? 100 : 1}),
            conversion_rate: Num({val: metrics.conversion_rate || 0, multiplier: decimalToPercentage ? 100 : 1}),
        }
    }
    if (optionalMetrics) {
        let om = {}
        if (optionalMetrics.includes('tos_is')) {
            om.tos_is = metrics.tos_is ? round(metrics.tos_is) : "--"
        }
        o = {...o, ...om}
    }

    if (retail_metrics) {
        o = {
            ...o,
            total_acos: Num({val: metrics.total_acos || 0, multiplier: decimalToPercentage ? 100 : 1}),
            total_sales: round(metrics.total_sales || 0),
            total_units: metrics.total_units || 0,
            total_orders: metrics.total_orders || 0,
            ordered_revenue: round(metrics.ordered_revenue || 0),
            units_ordered: metrics.units_ordered || 0,
            ordered_orders: metrics.ordered_orders || 0,
            shipped_revenue: round(metrics.shipped_revenue || 0),
            shipped_units: metrics.shipped_units || 0,
            shipped_cogs: round(metrics.shipped_cogs || 0),
            page_views: metrics.page_views || 0,
            sessions: metrics.sessions || 0,
            buy_box_percentage: round(metrics.buy_box_percentage || 0),
            ordered_items_session_percentage: round(metrics.ordered_items_session_percentage || 0),
            average_offer_count: metrics.average_offer_count || 0,
            feedback_received: metrics.feedback_received || 0,
            received_negative_feedback_rate: round(metrics.received_negative_feedback_rate || 0),
            claims_granted: metrics.claims_granted || 0,
            claims_amount: round(metrics.claims_amount || 0),
            browser_page_views: metrics.browser_page_views || 0,
            browser_sessions: metrics.browser_sessions || 0,
            mobile_page_views: metrics.mobile_page_views || 0,
            mobile_app_sessions: metrics.mobile_app_sessions || 0
        }
    }
    if (prefix) {
        let _o = {}
        Object.keys(o).forEach(key => {
            _o[`${prefix}${key}`] = o[key]
        })
        o = _o
    }
    return o;
}

function downloadCSV(array, filename) {

	const link = document.createElement('a');
    // By default Excel uses ANSI encoding for csv files which does not include most of the special characters
    // \ufeff is a universal BOM (Byte Order Mark) -> This tells the application to read the file in UTF-8 format
    const blob = new Blob(["\ufeff" + convertArrayOfObjectsToCSV({array})], {type: "text/csv"});
    const data = URL.createObjectURL(blob)
    
    link.setAttribute('href', data);
	link.setAttribute('download', filename);
	link.click();

}

function differenceSet(setA, setB) {
    let _difference = new Set(setA)
    for (let elem of setB) {
        _difference.delete(elem)
    }
    return _difference
}

const handleAmazonLogin = ({suffix, state}={}) => {
    let url = "https://www.amazon.com/ap/oa?client_id=amzn1.application-oa2-client.99a7e526d96d40afacddb74b3ce6d8fa&scope=advertising::campaign_management profile&response_type=code"

    if (state) {
        url += `&state=${state}`
    }

    url += `&redirect_uri=${BASE_APP_URL}/accounts_manager${suffix ? encodeURIComponent(suffix) : ""}`

    window.location = url
}

const handleReauthorize = (id) => {
    localStorage.setItem("reauth_account_id", id)
    handleAmazonLogin()
}

const confirmAccountLinking = (MySwal) => {
    MySwal.fire({
        title: '',
        text: "You will be redirected to Amazon where you'll be prompted to login and authorize Adbrew to access your advertising campaign data. You'll then be redirected back to Adbrew, where you can choose a specific account and country to connect with Adbrew",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Login with Amazon',
        showClass: {
            popup: 'animate__animated animate__fadeInDown'
          },
          hideClass: {
            popup: 'animate__animated animate__fadeOutUp'
          }
    }).then((apply) => {
        if (apply.value) {
            localStorage.removeItem("reauth_account_id")
            handleAmazonLogin();
        } 
    });
};

const getDisplayableHour = (hour) => {
    hour = parseInt(hour)
    let suffix = "AM"
    let prefix = hour
    if (hour >= 12) {
        suffix = "PM"
    }
    if (hour == 0) prefix = "12"
    if (hour > 12) prefix = hour - 12
    return `${prefix}${suffix}`
}

const splitInBatches = (val_array, batch_size) => {
    let batches = []
    for (let i = 0; i<val_array.length; i = i+batch_size) {
      batches.push(val_array.slice(i, i+batch_size))
    }
    return batches
}

export {
    getDisplayableHour,
    getValueType,
    isNumeric,
    resolveValue,
    capitalize,
    isValidRulesetId,
    round,
    getTargetDescription,
    getTargetText,
    getTargetType,
    prefixCurrency,
    removeDuplicates,
    getSearchTermKey,
    isAsin,
    removeASINPrefix,
    getBidConstraints,
    userInOrg,
    convertArrayOfObjectsToCSV,
    downloadCSV,
    differenceSet,
    getChangeDescriptionAndTargetName,
    getTaskTargetType,
    getTargetName,
    customCampaignDropdownFilter,
    metricsForExport,
    pluralize,
    handleAmazonLogin,
    confirmAccountLinking,
    handleReauthorize,
    getDisplayableTime,
    getTimeDiff,
    splitInBatches
}

export function getSelectedAccountFromStorage() {
    return JSON.parse(localStorage.getItem("account") || "{}");
}

export function isAccountLinked() {
    return JSON.parse(localStorage.getItem("account") || "{}").name !== undefined;
}

export function isValidEmail(email) {
    var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
    if (!pattern.test(email)) {
        return "Invalid"  
    }
    else{
        return "Success"
    }
}

export function flatten(arrays) {
    return [].concat.apply([], arrays);
}

export function getReactFlagCountryCode(countryCode) {
    const m = {
        'UK': 'GB'
    }

    return m[countryCode] || countryCode
}

export function handleEnableCampaignAutomation(swalInstance, onConfirmation) {
    swalInstance.fire({
        title: 'Are you sure?',
        text: "If these campaigns have automated bid or target management rulesets associated with them, automation will be enabled for selected campaigns.",
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes'
    }).then((apply) => {
        if (apply.value) {
            onConfirmation({ automated: true, update_message: 'Automation enabled' })
        }
    })
}

const metricInPercent = (v, selectedAccountDetails) => Num({missing: "--", val: v, suffix: "%", countryCode: selectedAccountDetails.country_code, currencyCode: selectedAccountDetails.currency_code})
const metricInCurrency = (v, selectedAccountDetails) => Num({missing: "--", val: v, prefixCurr: true, countryCode: selectedAccountDetails.country_code, currencyCode: selectedAccountDetails.currency_code})
export const metricInNoUnit = (v, selectedAccountDetails) => Num({missing: "--", val: v, countryCode: selectedAccountDetails.country_code, currencyCode: selectedAccountDetails.currency_code})

export const absoluteMetricsFormatter = {
    "acos": metricInPercent,
    "roas": metricInNoUnit,
    "spend": metricInCurrency,
    "sales": metricInCurrency,
    "cpc": metricInCurrency,
    "conversion_rate": metricInPercent,
    "conversions": metricInNoUnit,
    "clicks": metricInNoUnit,
    "impressions": metricInNoUnit,
    "ctr": metricInPercent,
    "orders": metricInNoUnit,
    "sb_sov": metricInPercent,
    "sp_sov": metricInPercent,
    "organic_sov": metricInPercent,
    "sp_tos_sov": metricInPercent,
    "total_sov": metricInPercent,
}

export function replaceAll(str, find, replace) {
    return str.replace(new RegExp(find, 'g'), replace);
}

export function handleDisableCampaignAutomation(swalInstance, onConfirmation) {
    swalInstance.fire({
        title: 'Are you sure?',
        text: "If these campaigns have automated bid or target management rulesets associated with them, automation will be disabled for these selected campaigns.",
        icon: "info",
        showCloseButton: true,
        showCancelButton: true,
        confirmButtonText: 'Yes'
    }).then((apply) => {
        if(apply.value) {
            onConfirmation({ automated: false, update_message: 'Automation disabled' })
        }
    })
}

// Copies a string to the clipboard. Must be called from within an
// event handler such as click. May return false if it failed, but
// this is not always possible. Browser support for Chrome 43+,
// Firefox 42+, Safari 10+, Edge and Internet Explorer 10+.
// Internet Explorer: The clipboard feature may be disabled by
// an administrator. By default a prompt is shown the first
// time the clipboard is used (per session).
export function copyToClipboard(text) {
    if (window.clipboardData && window.clipboardData.setData) {
        // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
        return window.clipboardData.setData("Text", text);

    }
    else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
        var textarea = document.createElement("textarea");
        textarea.textContent = text;
        textarea.style.position = "fixed";  // Prevent scrolling to bottom of page in Microsoft Edge.
        document.body.appendChild(textarea);
        textarea.select();
        try {
            return document.execCommand("copy");  // Security exception may be thrown by some browsers.
        }
        catch (ex) {
            console.warn("Copy to clipboard failed.", ex);
            return prompt("Copy to clipboard: Ctrl+C, Enter", text);
        }
        finally {
            document.body.removeChild(textarea);
        }
    }
}


export function catchAsync(fn, successMessage, failureMessage, throwError) {
    return async function (...args) {
        try {
            await fn(...args);
            if (successMessage) {
                Swal.fire({
                    title: 'Success',
                    text: successMessage,
                    icon: 'success',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#3B82F6'
                })
            }
        } catch (error) {
            console.log(error)
            if (failureMessage) {
                Swal.fire({
                    title: 'Error',
                    text: failureMessage,
                    icon: 'error',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#3B82F6'
                })
            }

            if (throwError) {
                throw error;
            }
        }
    }
}

export function getChange({task, type, target}) {
    if (!task) return ""
    if (type === 'keywords' && target) {
        return `${capitalize(target.match_type)} Keyword`
    } else if (type == 'targets' && target) {
        const {type: target_type} = target.resolved_target || {};
        if (target_type == 'category') {
            return 'Product Category'
        } else if (target_type == 'auto') {
            return 'Auto'
        }
        return 'Product'
    } else if (task.target_type) {
        return TARGET_TYPE_MAP[task.target_type]
    } else if (task.task_type === 'campaign_management') {
        return `${task.campaign_type?.toUpperCase()} Campaign`
    }
}

function handleAddLabelItems(labels = [], label, items, item) {
    if (labels == null) {
        labels = []
    }
    if (labels.indexOf(label) == -1) {
        items.push(item)
    }
}

function handleRemoveLabelItems(labels = [], label, items, item) {
    for (var i = 0; i < labels.length; i++) {
        if (labels[i] === label) {
            items.push(item)
        }
    }
}

export async function getLabelItems(key, type, items, selectedRows, label, itemKey) {
    if (key === 'products') {
        if (type === 'add') {
            selectedRows.forEach(row => {
                const { product_detail = {} } = row
                handleAddLabelItems(product_detail.labels, label, items, row[itemKey])
            })
        } else {
            selectedRows.forEach(row => {
                const { product_detail = {} } = row
                handleRemoveLabelItems(product_detail.labels, label, items, row[itemKey])
            })
        }
    } else if (key === 'campaigns') {
        if (type === 'add') {
            selectedRows.forEach(row => {
                const { labels = [] } = row
                handleAddLabelItems(labels, label, items, row[itemKey])
            })
        } else {
            selectedRows.forEach(row => {
                const { labels = [] } = row
                handleRemoveLabelItems(labels, label, items, row[itemKey])
            })
        }
    }
}

export const getPlacementText = (placement) => {
    if (placement === "Detail Page on-Amazon") {
        return "Product pages"
    } else if (placement === "Other on-Amazon") {
        return "Rest of search"
    } else if (placement === "Other Placements") {
        return "Other Placements"
    } else if (placement === "Top of Search") {
        return "Top of Search"
    } else {
        return "Top of search on-Amazon"
    }
}

/**
 * get resolved placement predicate for the placement
 * @param {String} placement 
 * @returns {String}
 */
export const getPlacementPredicate = (placement) => {
    let predicate = ""
    if (placement == "Detail Page on-Amazon") {
        predicate = "placementProductPage"
    } else if (placement == "Top of Search on-Amazon") {
        predicate = "placementTop"
    } else if (placement == "Other on-Amazon") {
        predicate = "placementRestOfSearch"
    }
    return predicate
}
/**
 * makes the placement bid adjustment change
 * if the placement already exists in the adjustments array, updates the percentage
 * else adds the placement to the adjustments array
 * @param {Object} biddingToUpdate 
 * @param {String} placementPredicate 
 * @param {Number} percentage 
 * @returns 
 */
export const makePlacementBidAdjustmentChange = async (biddingToUpdate, placementPredicate, percentage) => {
    const idx = (biddingToUpdate.adjustments).findIndex(({predicate}) => predicate === placementPredicate)
    if (idx > -1) {
        biddingToUpdate.adjustments[idx]['percentage'] = percentage
    } else {
        biddingToUpdate.adjustments.push({predicate: placementPredicate, percentage})
    }
    return biddingToUpdate
}
/**
 * Builds an object which can be used to either create the displayable filter options for the filter component or to create a filter config to be used in the RAW_FILTER_CONFIGS constant in rawFilters.js
 * @param {Boolean} isRawFilterConfig if True, returns the raw filter object body with the label, attribute and value options. Else returns the filter config object with the whitelisted keys and config for the filter type
 * @param {String} prefix prefix for the attribute
 * @param {String} suffix suffix for the attribute
 * @param {String} labelPrefix prefix in the displayed label
 * @param {String} labelSufix suffix in the displayed label 
 * @returns {Object}
 */  

/**
 * Returns the value of the object at the path specified
 * Example: getDeepValueFromObject({a: {b: {c: 1}}}, 'a.b.c') returns 1
 * @param {*} obj The object from which the value is to be extracted
 * @param {*} path string path to the value in the object
 * @param {*} default_value default value to be returned if the path is not found in the object
 * @returns The value at the path in the object
 */
export function getDeepValueFromObject(obj, path, default_value) {
    let o = obj;
    for (var i = 0, path = path.split('.'), len = path.length; i < len; i++) {
        o = o ? o[path[i]] ?? default_value : default_value;
    };
    return o;
}

export const formatDate = (date) => {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}${month}${day}`;
};

export function getInnerTextById(id) {
    let node = document.getElementById(id)
    let res = node ? node.innerText : ""
    res = res.replace(/,/g, ' ').replace(/\n/g, '\t')
    return res
}

/** 
 * @param {String} d1 - From Date
 * @param {String} d2 - To Date
 * @returns {Number}
 */
export const getDateDifferenceInDays = (d1, d2) => {
    const date1 = new Date(d1)
    const date2 = new Date(d2)
    const diffTime = Math.abs(date2 - date1);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
    return diffDays
}

export const requestIdMappedPromise = async (p, internalAppRequestId) => {
    const res = await p
    return {
        internalAppRequestId,
        ...res
    }
}

export const whitelistFilter = (obj, filter) => Object.entries(obj)
    .filter(([key, val])=> filter.includes(key))
    .reduce((obj, [key, val]) => (obj[key] = val, obj), {})

export const generateUniqueId = () => {
    const timestamp = new Date().getTime().toString(16);
    const random = Math.floor(Math.random() * 1000000).toString(16);
    return `${timestamp}-${random}`;
};

export const injectAdgroupFilter = (filter, campaignId, selectedAdGroup) => {
    const campaignFilter = {
        label: RAW_FILTER_CONFIGS[LABELS.CAMPAIGN].config.label,
        attribute: RAW_FILTER_CONFIGS[LABELS.CAMPAIGN].config.attribute,
        operator: FILTER_OPERATORS.EQUALS,
        value: [
            {
                campaignId: campaignId,
                adGroupId: selectedAdGroup,
            },
        ],
    }

    if (!filter || filter.length === 0) {
        return [campaignFilter];
    } else {
        let foundCampaignFilter = false;
        const updatedFilter = filter.map(f => {
            if (f.attribute === RAW_FILTER_CONFIGS[LABELS.CAMPAIGN].config.attribute) {
                foundMatchingAttribute = true;
                return {
                    ...f,
                    value: [{
                        campaignId: campaignId,
                        adGroupId: selectedAdGroup,
                    }],
                };
            } else {
                return f;
            }
        });

        if (!foundCampaignFilter) {
            updatedFilter.push(campaignFilter);
        }

        return updatedFilter;
    }
};

/**
 * Locally sorts an array of objects based on a given selector and direction, row with `aggregate` property remains at the top.
 *
 * Sorts in ascending order by default, or descending if `direction` is "desc".
 * 
 * Throws an error if values at the specified selector have different types.
 *
 * @param {Array<Object>} rows - The array of objects to sort.
 * @param {String} selector - The selector string indicating the property to sort on.
 * @param {String} direction - The sorting direction, either "asc" (ascending) or "desc" (descending).
 * @returns {Array<Object>} A new array containing the sorted objects.
 * @throws If rows are not objects, or values at the selector have different types.
 */
export function sortWithAggregateRow(rows, selector, direction) {
    if (!Array.isArray(rows)) {
        throw new TypeError('Rows must be an array');
    }

    if (selector == null) {
        return [...rows];
    }

    rows.sort((a, b) => {
        if (a["aggregate"]) {
            return -1;
        } else if (b["aggregate"]) {
            return 1;
        }
        const va = getDeepValueFromObject(a, selector, null);
        const vb = getDeepValueFromObject(b, selector, null);
        if (va === null || vb === null || typeof va !== typeof vb) {
            throw `Cannot compare ${va} and ${vb}`;
        }

        if (typeof va === 'string' || va instanceof String) {
            return (direction === 'asc' ? va.localeCompare(vb) : vb.localeCompare(va));
        } else if (typeof va === 'number' || va instanceof Number) {
            return (direction === 'asc' ? va - vb : vb - va);
        } else {
            throw `Not implemented for type ${typeof va}`;
        }
    });

    return [...rows];
}

export function AMCExportForMetrics({ metrics, data, prefix = "" }) {
    const percentageMetrics = [
        AMC_METRICS.PERCENTAGE,
        AMC_METRICS.CTR,
        AMC_METRICS.ACOS,
        AMC_METRICS.CONVERSION_RATE,
        AMC_METRICS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS,
        AMC_METRICS.CONVERSION_RATE_PER_CLICK,
    ];

    const formatValue = (metric, value) => {
        if (percentageMetrics.includes(metric)) {
            return round(value * 100)
        }

        return round(value)
    }

    return metrics.reduce((result, metric) => {
        const value = getDeepValueFromObject(data, `${prefix}${metric}`, null)
        result[`${prefix}${metric}`] = formatValue(metric, value)
        return result
    }, {})
}

// For use by Top/Trends Dashboard widget
export const filterOutUnlabelledData = (data, type) => {
    if (!["campaigns", "keywords", "sov", "search_terms", "targets"].includes(type))
        return data
    return data.filter(d => d.label)
}

export const isDemoMode = () => {
    return localStorage.getItem("demo_mode") === "true";
}

export const getDemoStr = (n, prefix, addSuffix=true) => {
    if (!isDemoMode()) {
        return n;
    }
    if (n) {
        return `${prefix || "Demo"} ${addSuffix ? Math.floor(Math.random() * 100) : ''}`
    }
    return n
}

export const getDemoNum = (v) => {
    if (!isDemoMode()) {
        return v
    }
    let val = v * (0.5 + Math.random())
    if (Number.isInteger(v)) {
        return Math.floor(val)
    }
    return val
}