import PropTypes from 'prop-types';
import React from 'react'

function AdbInput({
    label,
    placeholder = "Enter text here",
    id,
    name,
    type = "text",
    defaultValue,
    value,
    onChange,
    disabled = false,
    prefixSuffixSymbol = {},
    labelStyles = "",
    inputStyles = "",
    containerStyles = "",
    ...otherProps
  }) {
    return (
      <div className={`tw-w-full ${containerStyles}`}>
        {label &&
          <label htmlFor={name} className={`tw-block tw-text-xs tw-font-medium tw-leading-6 tw-text-brand-text-gray2 ${labelStyles}`}>
            {label}
          </label>
        }
        <div className={`${label ? "tw-mt-2" : ""} ${prefixSuffixSymbol.prefix ||  prefixSuffixSymbol.suffix ? "tw-relative" : ""}`}>
          {Object.keys(prefixSuffixSymbol).length &&  
            <span className={`tw-absolute tw-top-[21%] tw-px-2.5 ${prefixSuffixSymbol.suffix ? "tw-right-0" : ""} ${prefixSuffixSymbol.symbolStyles}`}>{prefixSuffixSymbol.prefix || prefixSuffixSymbol.suffix}</span>
          }
          <input
            value={value || defaultValue}
            id={id || name}
            name={name}
            type={type}
            onChange={onChange}
            placeholder={placeholder}
            disabled={disabled}
            className={`tw-block tw-w-full tw-rounded-md tw-border-0 tw-py-1 tw-px-3 tw-text-brand-header-gray tw-ring-1 tw-ring-brand-border-gray tw-placeholder:text-gray-400 focus:tw-ring-inset focus:tw-ring-brand-blue disabled:tw-cursor-not-allowed disabled:tw-bg-gray-50 disabled:tw-text-gray-500 disabled:tw-ring-gray-200 tw-text-xs tw-leading-6 tw-shadow-brand-input ${inputStyles} ${prefixSuffixSymbol.prefix ? "tw-pl-9" : ""}`}
            {...otherProps}
          />
        </div>
      </div>
    );
}

/**
 * Not an actual input, but just a span with disabled styles.
 */
export function DisabledInput({text, customStyles}) {
  return (
    <span className={`tw-inline-flex tw-items-center tw-h-8 tw-text-xs hover:tw-cursor-not-allowed tw-rounded-md tw-border tw-border-brand-border-gray tw-px-3 tw-text-gray-500 tw-bg-brand-gray-light ${customStyles}`}>
      {text}
    </span>
  )
}

export function InputWithNote({ children, ...inputProps }) {
  return (
    <div>
      <AdbInput {...inputProps} />
      <div className="tw-text-small-tags tw-text-brand-text-gray2 tw-mt-3">
        {children}
      </div>
    </div>
  );
}


AdbInput.propTypes = {
  label: PropTypes.string,
  placeholder: PropTypes.string,
  id: PropTypes.string,
  name: PropTypes.string,
  type: PropTypes.string,
  defaultValue: PropTypes.any,
  value: PropTypes.any,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  labelStyles: PropTypes.string,
  inputStyles: PropTypes.string,
  containerStyles: PropTypes.string
};

export default AdbInput