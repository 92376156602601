import React from "react";

function Spinner({ loaderStyles = "" }) {
  return (
    <div
      className={`progress-loader !tw-text-[3px] tw-mx-3 ${loaderStyles}`}
      style={{
        animation: "mulShdSpin 1.2s infinite linear",
        color: "currentColor",
      }}
    ></div>
  );
}

function AdbLoader({
  pageLoader = false,
  containerStyles = "",
  loaderStyles = "",
  loaderColor
}) {
  return (
    <>
      {pageLoader && (
        <div
          className={`tw-flex tw-h-72 tw-items-center tw-justify-center ${containerStyles}`}
        >
          <Spinner
            loaderStyles={
              "!tw-text-small-tags !tw-text-brand-icon-gray " + loaderStyles
            }
          />
        </div>
      )}
      {!pageLoader && (
        <Spinner loaderStyles={`${loaderColor || "!tw-text-brand-orange"} ${loaderStyles}`} />
      )}
    </>
  );
}

export default AdbLoader;