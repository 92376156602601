import React, { useEffect, useRef, useState } from "react";

import AdbButton from "../AdbButton";
import ReactTooltip from "react-tooltip";
import AdbIconPencil from "../../adb-icons/AdbIconPencil";
import {
  iconClassesWithHover,
} from "../../utils/commonTailwindClasses";
import AdbDropdown from "../AdbDropdown";
import AdbInput from "../AdbInput";
import { prefixCurrency } from "../../Util";
import AdbLoader from "../AdbLoader";
import ModalWithInput from "../ModalWithInput";

const FONT_WEIGHTS = {
  600: "tw-font-semibold"
}

function CellEditModal({
  text,
  onEdit,
  onClose,
  onEditSuccess,
  onEditError,
  inputValidaton = () => true,
  setIsLoading,
  prefixSuffixSymbol = {}
}) {
  const [content, setContent] = useState(text);
  const [disableSave, setDisableSave] = useState(true);

  useEffect(
    function () {
      if (content.length === 0 || content === text) {
        setDisableSave(true);
      } else {
        setDisableSave(false);
      }
    },
    [content],
  );

  async function handleSave() {
    if (content.length === 0 || content == text) {
      return;
    }

    if (!inputValidaton(content)) {
      setIsLoading(false);
      return;
    }

    try {
      setIsLoading(true);
      await onEdit(content);

      onEditSuccess?.(content);
    } catch (error) {
      onEditError?.(error);
    } finally {
      setIsLoading(false);
    }
  }

  if (Object.keys(prefixSuffixSymbol).length) {
    if (prefixSuffixSymbol.isCurrency) {
      prefixSuffixSymbol.symbolStyles = "tw-border-r tw-pr-2"
    } else if (prefixSuffixSymbol.isPercentage) {
      prefixSuffixSymbol.symbolStyles = "tw-border-l"
    }

    prefixSuffixSymbol.symbolStyles += " tw-text-brand-text-gray2 tw-border-brand-border-gray"
  }

  return (
    <ModalWithInput
      content={content}
      setContent={setContent}
      onClose={onClose}
      onSave={handleSave}
      disableSave={disableSave}
      prefixSuffixSymbol={prefixSuffixSymbol}
      inputStyles="!tw-text-brand-text-gray2"
    />
  );
}

function TableCell({
  text,
  isName,
  rightAlign,
  fontWeight="",
  onEdit,
  onEditSuccess,
  onEditError,
  inputValidaton,
  wrapTwoLines,
  prefixSuffixSymbol = {}
}) {
  const textRef = useRef(null);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEditIcon, setShowEditIcon] = useState(false);
  const [editMenuOpened, setEditMenuOpened] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  function handleTextMouseEnter() {
    if (textRef.current) {
      setShowTooltip(
        textRef.current.scrollHeight > textRef.current.clientHeight,
      );
    }
  }

  function handleTextMouseLeave() {
    setShowTooltip(false);
  }

  function handleCellMouseEnter() {
    if (onEdit) {
      setShowEditIcon(true);
    }
  }

  function handleCellMouseLeave() {
    if (onEdit && !editMenuOpened) {
      setShowEditIcon(false);
    }
  }

  function handleOpenEditMenu() {
    setEditMenuOpened(true);
  }

  function handleCloseEditMenu() {
    setShowEditIcon(false);
    setEditMenuOpened(false);
  }

  let displayText = text;

  if (prefixSuffixSymbol.prefix) displayText = prefixSuffixSymbol.prefix + displayText;
  else if (prefixSuffixSymbol.suffix) displayText += prefixSuffixSymbol.suffix;

  if (fontWeight && FONT_WEIGHTS[fontWeight]) fontWeight = FONT_WEIGHTS[fontWeight]

  return (
    <div
      className={`tw-flex tw-w-full tw-justify-between tw-items-center tw-h-full ${
        rightAlign ? "tw-flex-row-reverse" : ""
      } ${fontWeight}` }
      onMouseEnter={handleCellMouseEnter}
      onMouseLeave={handleCellMouseLeave}
    >
      {isLoading ? (
        <AdbLoader loaderColor="!tw-text-brand-icon-gray" />
      ) : (
        <>
          <div
            ref={textRef}
            className={`tw-w-3/4 tw-overflow-hidden tw-text-ellipsis tw-relative tw-to-brand-text-gray2 ${
              wrapTwoLines
                ? "tw-whitespace-normal tw-line-clamp-2"
                : "tw-whitespace-nowrap"
            } ${isName ? "tw-text-brand-blue tw-font-semibold" : ""}
            ${rightAlign ? "tw-text-right" : ""}`}
            style={{
              display: wrapTwoLines ? "-webkit-box" : "block",
              WebkitLineClamp: wrapTwoLines ? 2 : 1,
              WebkitBoxOrient: "vertical",
            }}
            {...(showTooltip
              ? { "data-tip": displayText, "data-for": `tooltip-${displayText}` }
              : {})}
          >
            <div
              onMouseEnter={handleTextMouseEnter}
              onMouseLeave={handleTextMouseLeave}
            >
              {displayText}
            </div>
            {showTooltip && (
              <ReactTooltip id={`tooltip-${text}`} effect="solid" place="right">
                {displayText}
              </ReactTooltip>
            )}
          </div>
          {showEditIcon && (
            <AdbDropdown
              dropdownButton={
                <div onClick={handleOpenEditMenu}>
                  <AdbIconPencil className={iconClassesWithHover} />
                </div>
              }
              dropdownItemsProps={{
                twWidth: "!tw-w-80 !tw-max-w-80",
                position: "bottom center",
                transitionOrigin: "top-left",
              }}
              onCloseDropdown={handleCloseEditMenu}
            >
              <CellEditModal
                text={text}
                onEdit={onEdit}
                onClose={() => setShowEditIcon(false)}
                onEditSuccess={onEditSuccess}
                onEditError={onEditError}
                inputValidaton={inputValidaton}
                setIsLoading={setIsLoading}
                prefixSuffixSymbol={prefixSuffixSymbol}
              />
            </AdbDropdown>
          )}
        </>
      )}
    </div>
  );
}

export default TableCell;
