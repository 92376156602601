import { BIDDING_STRATEGIES, METRIC_TYPES } from '../../../store/constant'
import { ADGROUP_BID_STRATEGY_VALUE_TO_LABEL, ADGROUP_DELIVERY_STATUS_VALUE_TO_LABEL, CAMPAIGN_BID_STRATEGY_VALUE_TO_LABEL, CAMPAIGN_DELIERY_STATUS_VALUE_TO_LABEL } from '../../dsp/constants'
import { DERIVED_GOAL_VALUE_TO_LABEL, TARGET_KPI_STATUS_CONFIGS } from '../../dsp/campaigns/constants'
import { INVENTORY_FIELDS } from '../../products/Classes/InventoryData'
import { capitalizeString } from './manipulations'
import { DELIVERY_PROFILE_VALUE_TO_LABEL, INVENTORY_TYPE_VALUE_TO_LABEL } from '../../dsp/adgroups/constants'
import { TARGETING_METHOD_VALUE_TO_LABEL } from '../../dsp/audiences/constants'

export const FILTER_KEYS = {
	SMART_RECOMMENDATIONS: 'smart_recommendations',
	DAYPARTING_STRATEGY: 'dayparting_strategy',
	CAMPAIGNS: 'campaigns',
	TERMS: 'terms',
	EXECUTIVE_DASHBOARD: 'executive_dashboard',
	SEARCH_TERMS: 'search_terms',
    N_GRAMS: 'n_grams',
	PRODUCTS: 'products',
	TARGETS: 'targets',
	TARGET_MANAGEMENT_TASKS: 'target_management_tasks',
	SERP: 'serp',
	PRODUCT_GOALS: 'product_goals',
	SEARCH_RANKS: 'search_ranks',
	SP_ACCOUNTS: 'sp_accounts',
	CHANGE_HISTORY_PERF: 'change_history_perf',
	CHANGE_HISTORY: 'change_history',
    AUTOMATED_RUNS: 'automated_runs',
    DAYPARTING_RUNS: 'dayparting_runs',
    ALL_PRODUCTS: 'all_products',
    BID_MANAGEMENT_TASKS: 'bid_management_tasks',
    BUDGET_MANAGEMENT_TASKS: 'budget_management_tasks',
    DAYPARTED_CAMPAIGNS: 'dayparted_campaigns',
    LINKED_CAMPAIGNS_MANAGEMENT: 'linked_campaigns_management',
    CHANGE_HISTORY_V2: 'change_history_v2',
    PLACEMENT: 'placement',
    LAUNCH_HISTORY: 'launch_history',
    NEGATIVE_TARGETS: 'negative_targets',
    AMC_PATH_TO_CONVERSION_REPORT_PATHS: 'amc_reports:path_to_conversion:paths',
    AMC_TIME_TO_CONVERSION_REPORT_CAMPAIGNS: 'amc_reports:time_to_conversion:campaigns',
    AMC_NEW_TO_BRAND_REPORT_CAMPAIGNS: 'amc_reports:new_to_brand:campaigns',
    AMC_ASIN_OVERLAP_REPORT_OVERLAPS: 'amc_reports:asin_overlap:overlaps',
    AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS: 'amc_reports:multi_touch_attribution:campaigns',
    AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGN_TYPES: 'amc_reports:multi_touch_attribution:campaign_types',
    AMC_TENTPOLE_FIRST_TOUCH_CAMPAIGNS: 'amc_reports:tentpole_first_touch:campaigns',
    AMC_AUDIENCES: 'amc_audiences',
    DSP_CAMPAIGNS: 'dsp_campaigns',
    DSP_PRODUCTS: 'dsp_products',
    DSP_ADGROUPS: 'dsp_adgroups',
    DSP_AUDIENCES: 'dsp_audiences',
    DSP_INVENTORY: 'dsp_inventory'
}

export const LABELS = {
    CAMPAIGN_NAME: "Campaign Name",
    ADGROUP_NAME: "Adgroup Name",
    BIDDING_STRATEGY: "Bidding Strategy",
    DAYPARTING_STRATEGY: "Dayparting Strategy",
    TARGETING: "Targeting",
    MATCH_TYPE: 'Match Type',
    CAMPAIGN_TYPES: "Campaign Types",
    CAMPAIGN: "Campaign",
    SEARCH_TERM: "Search Term",
    SEARCH_TERM_TYPE: "Search Term Type",
    CATEGORY: "Category",
    BRAND: "Brand",
    PRICE: "Price",
    PORTFOLIO: "Portfolio",
    AUTOMATION: "Automation",
    TARGETING_TYPE: "Targeting Type",
    AVAILABILITY: "Availability",
    STATE: "State",
    CAMPAIGN_TYPE: "Campaign Type",
    CAMPAIGN_STATE: "Campaign State",
    ASIN: "ASIN",
    SKU: "SKU",
    TARGET: "Target",
    TARGET_TERM_TYPE: "Target Term Type",
    CHANGE_TYPE: "Change Type",
    REASON: "Reason",
    TARGET_TYPE: "Target Type",
    CURRENT_BID: "Current Bid",
    BASE_BID_CURRENT_BID_DIFF: "Base - Current Bid Difference",
    BASE_BID: "Base Bid",
    ACOS: "ACOS",
    ROAS: "ROAS",
    SALES: "Sales",
    SPEND: "Spend",
    IMPRESSIONS: "Impressions",
    VIEWABLE_IMPRESSIONS: "Viewable Impressions",
    MEASURABLE_IMPRESSIONS: "Measurable Impressions",
    CLICKS: "Clicks",
    UNITS: "Units",
    ORDERS: "Orders",
    CONVERSION_RATE: "Conversion Rate",
    CTR: "CTR",
    CPC: "CPC",
    RUN_STATUS: "Run Status",
    BUDGET: "Budget",
    RULESET: "Ruleset",
    RULE_NAME: "Rule name",
    MOVE_AS_TARGET_TYPE: "Move as (Target Type)",
    LABELS: "Labels",
    CAMPAIGN_LABELS : "Campaign Label",
    ACCOUNTS: "Accounts",
    MARKETPLACE: "Marketplace",
    ACCOUNT_TYPE: "Account Type",
    TERM_TYPES: "Type",
    MATCH_CRITERIA: "Match Criteria",
    SCOPE: "Scope",
    RATING : "Rating",
    REVIEWS : "Reviews",
    FREQUENCY: "Frequency",
    HOURLY: "Hourly",
    DAILY: "Daily",
    BRAND_TERM: "Brand Term",
    COMPETITOR_TERM: "Competitor Term",
    HERO_TERM: "Hero Term",
    ACCOUNT: "Account",
    PRODUCT: "Product",
    BASE_BUDGET: "Base Budget",
    EXACT: "Exact",
    STARTS_WITH: "Starts with",
    CONTAINS: "Contains",
    MISSING_MATCH_TYPES: "Missing Match Types",
    NEGATIVE_MATCH_TYPES: "Negative Match Types",
    DAYS_OF_SUPPLY: capitalizeString(INVENTORY_FIELDS.DAYS_OF_SUPPLY),
    AFN_QUANTITY: `${INVENTORY_FIELDS.AFN.toUpperCase()} ${capitalizeString(INVENTORY_FIELDS.QUANTITY)}`,
    MFN_QUANTITY: `${INVENTORY_FIELDS.MFN.toUpperCase()} ${capitalizeString(INVENTORY_FIELDS.QUANTITY)}`,
    TOS_IS: "Top-of-search IS",
    TOS_BID_ADJUSTMENT: "Top-of-search bid adjustment (%)",
    PP_BID_ADJUSTMENT: "Product Pages bid adjustment (%)",
    ROS_BID_ADJUSTMENT: "Rest-of-search bid adjustment (%)",
    TOS_BID_BASE_ADJUSTMENT: "Top-of-search bid base adjustment (%)",
    PP_BID_BASE_ADJUSTMENT: "Product Pages bid base adjustment (%)",
    ROS_BID_BASE_ADJUSTMENT: "Rest-of-search bid base adjustment (%)",
    CAMPAIGN_FILTER: "Campaign ", // space is intentional to avoid confusion with CAMPAIGN. CAMPAIGN filter is already used and it shown adgroup selector. This is used to show campaign selector
    AVG_BUDGET_UTIL: "Average Budget Utilization",
    AVG_BUDGET_UTIL_HOUR: "Average Budget Utilization Hour",
    LAUNCH_STATUS: "Launch Status",
    AD_TYPES: "Ad Types",
    CPM: "CPM",
    CUSTOMERS: "Customers",
    CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS: "Conversion Rate per Thousand Impressions",
    CONVERSION_RATE_PER_CLICK: "Conversion Rate per Click",
    REACH: "Reach",
    MAX_BASE_BID_CHANGE: "Max Base Bid Change %",
    MIN_BASE_BID_CHANGE: "Min Base Bid Change %",
    BASE_BID_CHANGE: "Base Bid Change",
    BASE_BUDGET_CHANGE: "Base Budget Change",
    BASE_BIDDING_CHANGE: "Base Bidding Change",
    // AMC specific labels
    TIME_TO_CONVERSION_CATEGORY: "Time To Conversion",
    PATH_FREQUENCY: "Path Frequency",
    LEAD_ASIN: "Lead ASIN",
    LEAD_ASIN_ORDERS: "Lead ASIN Orders",
    LEAD_ASIN_CUSTOMERS: "Lead ASIN Customers",
    OVERLAP_ASIN: "Overlap ASIN",
    COMMON_CUSTOMERS: "Common Customers",
    USER_OVERLAP: "User Overlap",
    NEW_TO_BRAND_ORDERS: "New to Brand Orders",
    NEW_TO_BRAND_SALES: "New to Brand Sales",
    NEW_TO_BRAND_UNITS: "New to Brand Units",
    NEW_TO_BRAND_CUSTOMERS: "New to Brand Customers",
    NEW_TO_BRAND_ORDERS_PERCENTAGE: "New to Brand Orders Percentage",
    NEW_TO_BRAND_SALES_PERCENTAGE: "New to Brand Sales Percentage",
    // DSP specific labels
    DELIVERY_STATUS: "Delivery Status",
    DERIVED_GOAL: "Goal",
    BID_STRATEGY: "Bid Strategy",
    KPI_STATUS: "KPI Status",
    TOTAL_ACOS: "Total ACOS",
    TOTAL_ROAS: "Total ROAS",
    TOTAL_COST: "Total Cost",
    SUPPLY_COST: "Supply Cost",
    VIEWABILITY_RATE: "Viewability Rate",
    CLICK_THROUGHS: "Click Throughs",
    UNITS_SOLD: "Units Sold",
    TOTAL_UNITS_SOLD: "Total Units Sold",
    PURCHASES: "Purchases",
    TOTAL_PURCHASES: "Total Purchases",
    TOTAL_PURCHASES_RATE: "Total Purchases Rate",
    TOTAL_PRODUCT_SALES: "Total Product Sales",
    DPV: "DPV",
    DPVR: "DPVR",
    COST_PER_DETAIL_PAGE_VIEW: "Cost Per Detail Page View",
    TOTAL_DPV: "Total DPV",
    TOTAL_DPVR: "Total DPVR",
    NTB_DPV: "NTB - DPV",
    NTB_DPVR: "NTB - DPVR",
    TOTAL_NTB_DPV: "Total NTB - DPV",
    TOTAL_NTB_DPVR: "Total NTB - DPVR",
    ATC: "ATC",
    ATCR: "ATCR",
    TOTAL_ATC: "Total ATC",
    TOTAL_ATCR: "Total ATCR",
    BRAND_SEARCHES: "Brand Searches",
    BRAND_SEARCH_RATE: "Brand Search Rate",
    INVENTORY_TYPE: "Inventory Type",
    DELIVERY_PROFILE: "Delivery Profile",
    BASE_BID: "Base Bid",
    MAX_AVERAGE_CPM: "Max Average CPM",
    CATCH_UP_BOOST_PERCENTAGE: "Catch Up Boost Percentage",
    AUDIENCE_SEGMENT: "Audience Segment",
    TARGETING_METHOD: "Targeting Method",
    SUPPLY_SOURCE: "Supply Source",
    SITE: "Site",
    AUDIENCE_NAME: "Audience Name",
    AUDIENCE_TYPE: "Audience Type",
    AUDIENCE_COUNT: "Audience Count",
    AUDIENCE_STATUS: "Audience Status",
    AUDIENCE_ADVERTISER_TYPE: "Audience Advertiser Type",
    SPONSORED_ADS_INELIGIBLE: "Sponsored Ads Eligible",
}

export const FILTER_ATTRIBUTES = {
    RULESET_ID: "ruleset_id",
    CAMPAIGN_ID: "campaign_id",
    CAMPAIGN_NAME: "campaign_name",
}

export const FILTER_OPERATORS = {
    EQUALS: "=",
}

export const FILTER_TYPES = {
    CAMPAIGN_TYPE_WITH_DSP: "CAMPAIGN_TYPE_WITH_DSP",
    DSP_ACOS: "DSP_ACOS",
    DSP_TOTAL_ACOS: "DSP_TOTAL_ACOS",
    DSP_ROAS: "DSP_ROAS",
    DSP_TOTAL_ROAS: "DSP_TOTAL_ROAS",
    DSP_TOTAL_COST: "DSP_TOTAL_COST",
    DSP_SUPPLY_COST: "DSP_SUPPLY_COST",
    DSP_IMPRESSIONS: "DSP_IMPRESSIONS",
    DSP_MEASURABLE_IMPRESSIONS: "DSP_MEASURABLE_IMPRESSIONS",
    DSP_VIEWABLE_IMPRESSIONS: "DSP_VIEWABLE_IMPRESSIONS",
    DSP_VIEWABILITY_RATE: "DSP_VIEWABILITY_RATE",
    DSP_CLICK_THROUGHS: "DSP_CLICK_THROUGHS",
    DSP_CPM: "DSP_CPM",
    DSP_CTR: "DSP_CTR",
    DSP_CPC: "DSP_CPC",
    DSP_CPDPV: "DSP_CPDPV",
    DSP_UNITS_SOLD: "DSP_UNITS_SOLD",
    DSP_TOTAL_UNITS_SOLD: "DSP_TOTAL_UNITS_SOLD",
    DSP_PURCHASES: "DSP_PURCHASES",
    DSP_TOTAL_PURCHASES: "DSP_TOTAL_PURCHASES",
    DSP_TOTAL_PURCHASES_RATE: "DSP_TOTAL_PURCHASES_RATE",
    DSP_TOTAL_PRODUCT_SALES: "DSP_TOTAL_PRODUCT_SALES",
    DSP_DPV: "DSP_DPV",
    DSP_DPVR: "DSP_DPVR",
    DSP_TOTAL_DPV: "DSP_TOTAL_DPV",
    DSP_TOTAL_DPVR: "DSP_TOTAL_DPVR",
    DSP_NTB_DPV: "DSP_NTB_DPV",
    DSP_NTB_DPVR: "DSP_NTB_DPVR",
    DSP_TOTAL_NTB_DPV: "DSP_TOTAL_NTB_DPV",
    DSP_TOTAL_NTB_DPVR: "DSP_TOTAL_NTB_DPVR",
    DSP_ADD_TO_CART: "DSP_ADD_TO_CART",
    DSP_ATCR: "DSP_ATCR",
    DSP_TOTAL_ATC: "DSP_TOTAL_ATC",
    DSP_TOTAL_ATCR: "DSP_TOTAL_ATCR",
    DSP_BRAND_SEARCHES: "DSP_BRAND_SEARCHES",
    DSP_BRAND_SEARCH_RATE: "DSP_BRAND_SEARCH_RATE",
    DSP_BUDGET: "DSP_BUDGET",
    DSP_CAMPAIGN_DELIVERY_STATUS: "DSP_CAMPAIGN_DELIVERY_STATUS",
    DSP_CAMPAIGN_BID_STRATEGY: "DSP_CAMPAIGN_BID_STRATEGY",
    DSP_ADGROUP_DELIVERY_STATUS: "DSP_ADGROUP_DELIVERY_STATUS",
    DSP_ADGROUP_BID_STRATEGY: "DSP_ADGROUP_BID_STRATEGY",
    DSP_SALES: "DSP_SALES"
}

export const DSPMetricConfigs = {
    [FILTER_TYPES.DSP_ACOS]: {
        label: LABELS.ACOS,
        attribute: 'ACOS',
        hidden_by_default: true,
        metricType: METRIC_TYPES.ACOS,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_ACOS]: {
        label: LABELS.TOTAL_ACOS,
        attribute: 'totalACOS',
        metricType: METRIC_TYPES.ACOS,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_ROAS]: {
        label: LABELS.ROAS,
        attribute: 'ROAS',
        hidden_by_default: true,
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_ROAS]: {
        label: LABELS.TOTAL_ROAS,
        attribute: 'totalROAS',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_COST]: {
        label: LABELS.TOTAL_COST,
        attribute: 'totalCost',
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_SUPPLY_COST]: {
        label: LABELS.SUPPLY_COST,
        attribute: 'supplyCost',
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_IMPRESSIONS]: {
        label: LABELS.IMPRESSIONS,
        attribute: 'impressions',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_VIEWABILITY_RATE]: {
        label: LABELS.VIEWABILITY_RATE,
        attribute: 'viewabilityRate',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_CLICK_THROUGHS]: {
        label: LABELS.CLICK_THROUGHS,
        attribute: 'clicks',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_CPM]: {
        label: LABELS.CPM,
        attribute: 'eCPM',
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_CTR]: {
        label: LABELS.CTR,
        attribute: 'CTR',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_CPC]: {
        label: LABELS.CPC,
        attribute: 'eCPC',
        hidden_by_default: true,
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_UNITS_SOLD]: {
        label: LABELS.UNITS_SOLD,
        attribute: 'unitsSold',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_UNITS_SOLD]: {
        label: LABELS.TOTAL_UNITS_SOLD,
        attribute: 'totalUnitsSold',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_PURCHASES]: {
        label: LABELS.PURCHASES,
        attribute: 'purchases',
        hidden_by_default: true,
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_PURCHASES]: {
        label: LABELS.TOTAL_PURCHASES,
        attribute: 'totalPurchases',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_PURCHASES_RATE]: {
        label: LABELS.TOTAL_PURCHASES_RATE,
        attribute: 'totalPurchasesRate',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_PRODUCT_SALES]: {
        label: LABELS.TOTAL_PRODUCT_SALES,
        attribute: 'totalSales',
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_SALES]: {
        label: LABELS.SALES,
        attribute: 'sales',
        hidden_by_default: true,
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_INVENTORY, FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS]
    },
    [FILTER_TYPES.DSP_DPV]: {
        label: LABELS.DPV,
        attribute: 'detailPageViews',
        hidden_by_default: true,
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_DPVR]: {
        label: LABELS.DPVR,
        attribute: 'DPVR',
        hidden_by_default: true,
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_CPDPV]: {
        label: LABELS.COST_PER_DETAIL_PAGE_VIEW,
        attribute: "eCPDetailPageView",
        hidden_by_default: true,
        metricType: METRIC_TYPES.CURRENCY,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_DPV]: {
        label: LABELS.TOTAL_DPV,
        attribute: 'totalDetailPageView',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_DPVR]: {
        label: LABELS.TOTAL_DPVR,
        attribute: 'totalDPVR',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_NTB_DPV]: {
        label: LABELS.NTB_DPV,
        attribute: 'newToBrandDetailPageViews',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_NTB_DPVR]: {
        label: LABELS.NTB_DPVR,
        attribute: 'newToBrandDetailPageViewRate',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_NTB_DPV]: {
        label: LABELS.TOTAL_NTB_DPV,
        attribute: 'totalNewToBrandDPVs',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_NTB_DPVR]: {
        label: LABELS.TOTAL_NTB_DPVR,
        attribute: 'totalNewToBrandDetailPageViewRate',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_ADD_TO_CART]: {
        label: LABELS.ATC,
        attribute: 'addToCart',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_ATCR]: {
        label: LABELS.ATCR,
        attribute: 'ATCR',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_ATC]: {
        label: LABELS.TOTAL_ATC,
        attribute: 'totalAddToCart',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_PRODUCTS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_TOTAL_ATCR]: {
        label: LABELS.TOTAL_ATCR,
        attribute: 'totalATCR',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_BRAND_SEARCHES]: {
        label: LABELS.BRAND_SEARCHES,
        attribute: 'brandSearches',
        metricType: METRIC_TYPES.NUMERIC,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
    [FILTER_TYPES.DSP_BRAND_SEARCH_RATE]: {
        label: LABELS.BRAND_SEARCH_RATE,
        attribute: 'brandSearchRate',
        metricType: METRIC_TYPES.PERCENTAGE,
        whitelistedKeys: [FILTER_KEYS.DSP_CAMPAIGNS, FILTER_KEYS.DSP_ADGROUPS, FILTER_KEYS.DSP_AUDIENCES, FILTER_KEYS.DSP_INVENTORY]
    },
}

const DEFAULT = {
    config: {},
    values: []
}

export const RAW_FILTER_CONFIGS_DERIVED_METRIC_EXCLUDED = {
    [LABELS.CAMPAIGN_NAME]: {
        config: {label: LABELS.CAMPAIGN_NAME, attribute: FILTER_ATTRIBUTES.CAMPAIGN_NAME},
        values: []
    },
    [LABELS.ADGROUP_NAME]: {
        config: { label: LABELS.ADGROUP_NAME, attribute: "adgroup_name" },
        values: []
    },
    [LABELS.AVG_BUDGET_UTIL]: {
        config: { label: LABELS.AVG_BUDGET_UTIL, attribute: "avg_budget_usage_perc" },
        values: []
    },
    [LABELS.AVG_BUDGET_UTIL_HOUR]: {
        config: { label: LABELS.AVG_BUDGET_UTIL_HOUR, attribute: `avg_budget_usage_hour` },
        values: []
    },
    [LABELS.AFN_QUANTITY]: {
        config: { label: LABELS.AFN_QUANTITY, attribute: `${INVENTORY_FIELDS.AFN}_${INVENTORY_FIELDS.QUANTITY}` },
        values: []
    },
    [LABELS.MFN_QUANTITY]: {
        config: { label: LABELS.MFN_QUANTITY, attribute: `${INVENTORY_FIELDS.MFN}_${INVENTORY_FIELDS.QUANTITY}` },
        values: []
    },
    [LABELS.DAYS_OF_SUPPLY]: {
        config: { label: LABELS.DAYS_OF_SUPPLY, attribute: INVENTORY_FIELDS.DAYS_OF_SUPPLY },
        values: []
    },
    [LABELS.DAYPARTING_STRATEGY]: {
        config: { label: LABELS.DAYPARTING_STRATEGY, attribute: "dayparting_strategy_id" },
        values: []
    },
    [LABELS.MISSING_MATCH_TYPES]: {
        config: { label: LABELS.MISSING_MATCH_TYPES, attribute: "missing_match_types" },
        values: [
            { label: 'Broad', value: 'broad' },
            { label: 'Phrase', value: 'phrase' },
            { label: 'Exact', value: 'exact' },
            { label: 'Product', value: 'product' }
        ]
    },
    [LABELS.TARGETING]: {
        config: { label: LABELS.TARGETING, attribute: "target_types" },
        values: [
            { label: 'Auto', value: 'auto' },
            { label: 'Product', value: 'product' },
            { label: 'Category', value: 'category' }
        ]
    },
    [LABELS.MATCH_TYPE]: {
        config: { label: LABELS.MATCH_TYPE, attribute: "match_types" },
        values: [
            { label: 'Broad', value: 'broad' },
            { label: 'Phrase', value: 'phrase' },
            { label: 'Exact', value: 'exact' },
        ]
    },
    [LABELS.NEGATIVE_MATCH_TYPES]: {
        config: { label: LABELS.NEGATIVE_MATCH_TYPES, attribute: "matchType" },
        values: [
            { label: 'Negative Phrase', value: 'negativePhrase' },
            { label: 'Negative Exact', value: 'negativeExact' },
        ]
    },
    [LABELS.CAMPAIGN_TYPES]: {
        config: { label: LABELS.CAMPAIGN_TYPES, attribute: "audit_campaign_types" },
        values: [
            { label: 'Sponsored Products', value: 'sp' },
            { label: 'Sponsored Brands', value: 'sb' },
            { label: 'Sponsored Display', value: 'sd' }
        ]
    },
    [LABELS.RUN_STATUS]: {
        config: { label: LABELS.RUN_STATUS, attribute: "status" },
        values: [
            { label: 'Succeeded', value: 'success'},
            { label: 'Processing', value: 'processing'},
            { label: "Failed", value: 'failed'}
        ]
    },
    [LABELS.CAMPAIGN]: {
        config: { label: LABELS.CAMPAIGN, attribute: "adGroupId" },
        values: []
    },
    [LABELS.CAMPAIGN_FILTER]: {
        config: { label: LABELS.CAMPAIGN_FILTER, attribute: "campaignId" },
        values: []
    },
    [LABELS.SEARCH_TERM]: {
        config: { label: LABELS.SEARCH_TERM, attribute: "query" },
        values: []
    },
    [LABELS.SEARCH_TERM_TYPE]: {
        config: { label: LABELS.SEARCH_TERM_TYPE, attribute: "search_term_type" },
        values: [
            { value: 'brand_account', label: "Brand Terms for Account" },
            { value: 'competitor_account', label: "Competitor Terms for Account" },
            { value: 'hero_account', label: "Hero Terms for Account" },
        ]
    },
    [LABELS.CATEGORY]: {
        config: { label: LABELS.CATEGORY, attribute: "category" },
        values: []
    },
    [LABELS.BRAND]: {
        config: { label: LABELS.BRAND, attribute: "brand" },
        values: []
    },
    [LABELS.PRICE]: {
        config: { label: LABELS.PRICE, attribute: "price" },
        values: []
    },
    [LABELS.PORTFOLIO]: {
        config: { label: LABELS.PORTFOLIO, attribute: "portfolioId" },
        values: []
    },
    [LABELS.AUTOMATION]: {
        config: { label: LABELS.AUTOMATION, attribute: "automated" },
        values: [
            { label: 'Enabled', value: 0 },
            { label: 'Configured But Disabled', value: 1 },
            { label: 'Not Configured', value: 2 }
        ]
    },
    [LABELS.TARGETING_TYPE]: {
        config: { label: LABELS.TARGETING_TYPE, attribute: "targetingType" },
        values: [
            { label: 'Auto', value: 'auto' },
            { label: 'Manual', value: 'manual' },
        ]
    },
    [LABELS.AVAILABILITY]: {
        config: { label: LABELS.AVAILABILITY, attribute: "availability" },
        values: [
            { label: 'IN_STOCK', value: 'IN_STOCK' },
            { label: 'IN_STOCK_SCARCE', value: 'IN_STOCK_SCARCE' },
            { label: 'OUT_OF_STOCK', value: 'OUT_OF_STOCK' },
            { label: 'PREORDER', value: 'PREORDER' },
            { label: 'LEADTIME', value: 'LEADTIME' },
            { label: 'AVAILABLE_DATE', value: 'AVAILABLE_DATE' }
        ]
    },
    [LABELS.STATE]: {
        config: { label: LABELS.STATE, attribute: "state" },
        values: [
            { label: 'Enabled', value: 'enabled' },
            { label: 'Paused', value: 'paused' },
            { label: 'Archived', value: 'archived' }
        ]
    },
    [LABELS.CAMPAIGN_STATE]: {
        config: { label: LABELS.CAMPAIGN_STATE, attribute: "campaign_state" },
        values: [
            { label: 'Enabled', value: 'enabled' },
            { label: 'Paused', value: 'paused' },
            { label: 'Archived', value: 'archived' }
        ]
    },
    [LABELS.CAMPAIGN_TYPE]: {
        config: { label: LABELS.CAMPAIGN_TYPE, attribute: "campaign_type" },
        values: [
            { label: 'Sponsored Products', value: 'sp' },
            { label: 'Sponsored Brands', value: 'sb' },
            { label: 'Sponsored Display', value: 'sd' }
        ]
    },
    [LABELS.ASIN]: {
        config: { label: LABELS.ASIN, attribute: "asin" },
        values: []
    },
    [LABELS.SKU]: {
        config: { label: LABELS.SKU, attribute: "sku" },
        values: []
    },
    [LABELS.TARGET]: {
        config: { label: LABELS.TARGET, attribute: "target_description" },
        values: []
    },
    [LABELS.TARGET_TERM_TYPE]: {
        config: { label: LABELS.TARGET_TERM_TYPE, attribute: "target_term_type" },
        values: [
            { value: 'brand_account', label: "Brand Terms for Account" },
            { value: 'competitor_account', label: "Competitor Terms for Account" },
            { value: 'hero_account', label: "Hero Terms for Account" },
        ]
    },
    [LABELS.CHANGE_TYPE]: {
        config: { label: LABELS.CHANGE_TYPE, attribute: "task_type" },
        values: [
            { label: 'Bid Change', value: 'bid_optimization' },
            { label: 'Target Harvesting', value: 'target_management' },
            { label: 'Campaign Management', value: 'campaign_management' },
        ]
    },
    [LABELS.REASON]: {
        config: { label: LABELS.REASON, attribute: "reason" },
        values: [
            { label: 'High ACOS', value: 'high_acos' },
            { label: 'Low ACOS', value: 'low_acos' },
            { label: 'Low Clicks', value: 'low_clicks' },
            { label: 'Low CVR', value: 'low_cvr' },
            { label: 'High Clicks', value: 'high_click' },
            { label: 'High Spend', value: 'high_spend' },
            { label: 'Low CTR', value: 'low_ctr' },
        ]
    },
    [LABELS.TARGET_TYPE]: {
        config: { label: LABELS.TARGET_TYPE, attribute: "type" },
        values: [
            { label: 'Broad Keyword', value: 'broad' },
            { label: 'Phrase Keyword', value: 'phrase' },
            { label: 'Exact Keyword', value: 'exact' },
            { label: 'Auto', value: 'auto' },
            { label: 'Product', value: 'asinSameAs' },
            { label: 'Expanded Product', value: 'asinExpandedFrom'},
            { label: 'Brand', value: 'asinBrandSameAs' },
            { label: 'Category', value: 'asinCategorySameAs' }
        ]
    },
    [LABELS.CURRENT_BID]: {
        config: { label: LABELS.CURRENT_BID, attribute: "bid" },
        values: []
    },
    [LABELS.MAX_BASE_BID_CHANGE]: {
        config: { label: LABELS.MAX_BASE_BID_CHANGE, attribute: "max_base_bid_change" },
        values: []
    },
    [LABELS.MIN_BASE_BID_CHANGE]: {
        config: { label: LABELS.MIN_BASE_BID_CHANGE, attribute: "min_base_bid_change" },
        values: []
    },
    [LABELS.BASE_BID_CHANGE]: {
        config: { label: LABELS.BASE_BID_CHANGE, attribute: "base_bid_change" },
        values: []
    },
    [LABELS.BASE_BUDGET_CHANGE]: {
        config: { label: LABELS.BASE_BUDGET_CHANGE, attribute: "base_budget_change" },
        values: []
    },
    [LABELS.BASE_BIDDING_CHANGE]: {
        config: { label: LABELS.BASE_BIDDING_CHANGE, attribute: "base_bidding_change" },
        values: []
    },
    [LABELS.BASE_BID_CURRENT_BID_DIFF]: {
        config: { label: LABELS.BASE_BID_CURRENT_BID_DIFF, attribute: "base_current_bid_diff" },
        values: []
    },
    [LABELS.BASE_BID]: {
        config: { label: LABELS.BASE_BID, attribute: "base_bid" },
        values: []
    },
    [LABELS.ACOS]: {
        config: { label: LABELS.ACOS, attribute: "acos" },
        values: []
    },
    [LABELS.ROAS]: {
        config: { label: LABELS.ROAS, attribute: "roas" },
        values: []
    },
    [LABELS.SALES]: {
        config: { label: LABELS.SALES, attribute: "sales" },
        values: []
    },
    [LABELS.BASE_BUDGET]: {
        config: { label: LABELS.BASE_BUDGET, attribute: "base_budget" },
        values: []
    },
    [LABELS.SPEND]: {
        config: { label: LABELS.SPEND, attribute: "spend" },
        values: []
    },
    [LABELS.IMPRESSIONS]: {
        config: { label: LABELS.IMPRESSIONS, attribute: "impressions" },
        values: []
    },
    [LABELS.CLICKS]: {
        config: { label: LABELS.CLICKS, attribute: "clicks" },
        values: []
    },
    [LABELS.UNITS]: {
        config: { label: LABELS.UNITS, attribute: "orders" },
        values: []
    },
    [LABELS.CONVERSION_RATE]: {
        config: { label: LABELS.CONVERSION_RATE, attribute: "conversion_rate" },
        values: []
    },
    [LABELS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS]: {
        config: { label: LABELS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS, attribute: "conversion_rate_per_thousand_impressions" },
        values: []
    },
    [LABELS.CONVERSION_RATE_PER_CLICK]: {
        config: { label: LABELS.CONVERSION_RATE_PER_CLICK, attribute: "conversion_rate_per_click" },
        values: []
    },
    [LABELS.CTR]: {
        config: { label: LABELS.CTR, attribute: "ctr" },
        values: []
    },
    [LABELS.CPC]: {
        config: { label: LABELS.CPC, attribute: "cpc" },
        values: []
    },
    [LABELS.ORDERS]: {
        config: { label: LABELS.ORDERS, attribute: "conversions" },
        values: []
    },
    [LABELS.TOS_IS]: {
        config: { label: LABELS.TOS_IS, attribute: "tos_is" },
        values: []
    },
    [LABELS.BUDGET]: {
        config: { label: LABELS.BUDGET, attribute: "dailyBudget" },
        values: []
    },
    [LABELS.RULESET]: {
        config: { label: LABELS.RULESET, attribute: "ruleset_ids" },
        values: []
    },
    [LABELS.RULE_NAME]: {
        config: { label: LABELS.RULE_NAME, attribute: "rule_name" },
        values: []
    },
    [LABELS.MOVE_AS_TARGET_TYPE]: {
        config: { label: LABELS.MOVE_AS_TARGET_TYPE, attribute: "target_type" },
        values: []
    },
    [LABELS.LABELS]: {
        config: { label: LABELS.LABELS, attribute: "labels" },
        values: []
    },
    [LABELS.CAMPAIGN_LABELS]:{
        config: { label: LABELS.CAMPAIGN_LABELS, attribute: "campaign_labels"},
        values: []
    },
    [LABELS.ACCOUNTS]: {
        config: { label: LABELS.ACCOUNTS, attribute: "account_ids" },
        values: []
    },
    [LABELS.MARKETPLACE]: {
        config: { label: LABELS.MARKETPLACE, attribute: "country_code" },
        values: []
    },
    [LABELS.ACCOUNT_TYPE]: {
        config: { label: LABELS.MARKETPLACE, attribute: "sp_account_type" },
        values: [{ label: "Vendor", value: "vendor" }, { label: "Seller", value: "seller" }]
    },
    [LABELS.TERM_TYPES]: {
        config: { label: LABELS.TERM_TYPES, attribute: "term_type" },
        values: [{label : LABELS.BRAND_TERM, value: 'brand'},{label : LABELS.COMPETITOR_TERM, value: 'competitor'},{label : LABELS.HERO_TERM, value: 'hero'}]
    },
    [LABELS.SCOPE]: {
        config: { label: LABELS.SCOPE, attribute: "scope" },
        values: [{label : LABELS.ACCOUNT, value: 'account'},{label : LABELS.PRODUCT, value: 'product'}]
    },
    [LABELS.MATCH_CRITERIA]: {
        config: { label: LABELS.MATCH_CRITERIA, attribute: "match_criteria" },
        values: [{label : LABELS.EXACT, value: 'exact'},{label : LABELS.STARTS_WITH, value: 'starts with'},{label : LABELS.CONTAINS, value: 'contains'}]
    },
    [LABELS.RATING]: {
        config: { label: LABELS.RATING, attribute: "rating" },
        values: []
    },
    [LABELS.REVIEWS]: {
        config: { label: LABELS.REVIEWS, attribute: "reviews" },
        values: []
    },
    [LABELS.FREQUENCY]: {
        config: { label: LABELS.FREQUENCY, attribute: "cadence" },
        values: [{ label: LABELS.HOURLY, value: "hourly" }, { label: LABELS.DAILY, value: "daily" }]
    },
    [LABELS.TOS_BID_ADJUSTMENT]: {
        config: { label: LABELS.TOS_BID_ADJUSTMENT, attribute: "tos_bid_adjustment" },
        values: []
    },
    [LABELS.PP_BID_ADJUSTMENT]: {
        config: { label: LABELS.PP_BID_ADJUSTMENT, attribute: "pp_bid_adjustment" },
        values: []
    },
    [LABELS.LAUNCH_STATUS]: {
        config: { label: LABELS.LAUNCH_STATUS, attribute: "status" },
        values: [
            { label: 'Created', value: 'created' },
            { label: 'Not Created', value: 'not_created' },
        ]
    },
    [LABELS.BIDDING_STRATEGY]: {
        config: { label: LABELS.BIDDING_STRATEGY, attribute: "bidding.strategy" },
        values: Object.keys(BIDDING_STRATEGIES).map(k => ({
            label: BIDDING_STRATEGIES[k], value: k
        }))
    },
    [LABELS.ROS_BID_ADJUSTMENT]: {
        config: { label: LABELS.ROS_BID_ADJUSTMENT, attribute: "ros_bid_adjustment" },
        values: []
    },
    [LABELS.TOS_BID_BASE_ADJUSTMENT]: {
        config: { label: LABELS.TOS_BID_BASE_ADJUSTMENT, attribute: "tos_bid_base_adjustment" },
        values: []
    },
    [LABELS.PP_BID_BASE_ADJUSTMENT]: {
        config: { label: LABELS.PP_BID_BASE_ADJUSTMENT, attribute: "pp_bid_base_adjustment" },
        values: []
    },
    [LABELS.ROS_BID_BASE_ADJUSTMENT]: {
        config: { label: LABELS.ROS_BID_BASE_ADJUSTMENT, attribute: "ros_bid_base_adjustment" },
        values: []
    },
    [LABELS.AD_TYPES]: {
        config: { label: LABELS.AD_TYPES, attribute: "ad_types" },
        values: [
            { label: 'Sponsored Products', value: 'sp' },
            { label: 'Sponsored Brands', value: 'sb' },
            { label: 'Sponsored Display', value: 'sd' },
            { label: 'Demand Side Platform (DSP)', value: 'dsp' },
        ]
    },
    [LABELS.CPM]: {
        config: { label: LABELS.CPM, attribute: "cpm" },
        values: []
    },
    [LABELS.REACH]: {
        config: { label: LABELS.REACH, attribute: "reach" },
        values: []
    },
    [LABELS.CUSTOMERS]: {
        config: { label: LABELS.CUSTOMERS, attribute: "customers" },
        values: []
    },
    [FILTER_TYPES.CAMPAIGN_TYPE_WITH_DSP]: {
        config: { label: LABELS.CAMPAIGN_TYPE, attribute: "campaign_type" },
        values: [
            { label: 'Sponsored Products', value: 'sp' },
            { label: 'Sponsored Brands', value: 'sb' },
            { label: 'Sponsored Display', value: 'sd' },
            { label: 'Demand Side Platform (DSP)', value: 'dsp' },
        ]
    },
    // AMC specific filters
    [LABELS.TIME_TO_CONVERSION_CATEGORY]: {
        config: { label: LABELS.TIME_TO_CONVERSION_CATEGORY, attribute: "time_to_conversion_category" },
        values: [
            { label: '< 1 MIN', value: '< 1 MIN' },
            { label: '1 - 10 MIN', value: '1 - 10 MIN' },
            { label: '10 - 30 MIN', value: '10 - 30 MIN' },
            { label: '30 - 60 MIN', value: '30 - 60 MIN' },
            { label: '1 - 2 HRS', value: '1 - 2 HRS' },
            { label: '2 - 12 HRS', value: '2 - 12 HRS' },
            { label: '12 - 24 HRS', value: '12 - 24 HRS' },
            { label: '1 - 7 DAYS', value: '1 - 7 DAYS' },
            { label: '7 - 14 DAYS', value: '7 - 14 DAYS' },
            { label: '14 - 30 DAYS', value: '14 - 30 DAYS' },
            { label: '30+ DAYS', value: '30+ DAYS' },
        ]
    },
    [LABELS.PATH_FREQUENCY]: {
        config: { label: LABELS.PATH_FREQUENCY, attribute: "path_frequency" },
        values: []
    },
    [LABELS.LEAD_ASIN]: {
        config: { label: LABELS.LEAD_ASIN, attribute: "lead_asin" },
        values: []
    },
    [LABELS.LEAD_ASIN_ORDERS]: {
        config: { label: LABELS.LEAD_ASIN_ORDERS, attribute: "lead_asin_orders" },
        values: []
    },
    [LABELS.LEAD_ASIN_CUSTOMERS]: {
        config: { label: LABELS.LEAD_ASIN_CUSTOMERS, attribute: "lead_asin_customers" },
        values: []
    },
    [LABELS.OVERLAP_ASIN]: {
        config: { label: LABELS.OVERLAP_ASIN, attribute: "overlap_asin" },
        values: []
    },
    [LABELS.COMMON_CUSTOMERS]: {
        config: { label: LABELS.COMMON_CUSTOMERS, attribute: "common_customers" },
        values: []
    },
    [LABELS.USER_OVERLAP]: {
        config: { label: LABELS.USER_OVERLAP, attribute: "user_overlap" },
        values: []
    },
    [LABELS.NEW_TO_BRAND_ORDERS]: {
        config: { label: LABELS.NEW_TO_BRAND_ORDERS, attribute: "new_to_brand_orders" },
        values: []
    },
    [LABELS.NEW_TO_BRAND_SALES]: {
        config: { label: LABELS.NEW_TO_BRAND_SALES, attribute: "new_to_brand_sales" },
        values: []
    },
    [LABELS.NEW_TO_BRAND_CUSTOMERS]: {
        config: { label: LABELS.NEW_TO_BRAND_CUSTOMERS, attribute: "new_to_brand_customers" },
        values: []
    },
    [LABELS.NEW_TO_BRAND_UNITS]: {
        config: { label: LABELS.NEW_TO_BRAND_UNITS, attribute: "new_to_brand_units" },
        value: []
    },
    [LABELS.NEW_TO_BRAND_ORDERS_PERCENTAGE]: {
        config: { label: LABELS.NEW_TO_BRAND_ORDERS_PERCENTAGE, attribute: "new_to_brand_orders_percentage" },
        values: []
    },
    [LABELS.NEW_TO_BRAND_SALES_PERCENTAGE]: {
        config: { label: LABELS.NEW_TO_BRAND_SALES_PERCENTAGE, attribute: "new_to_brand_sales_percentage" },
        values: []
    },
    // DSP specific filters
    [FILTER_TYPES.DSP_CAMPAIGN_DELIVERY_STATUS]: {
        config: { label: LABELS.DELIVERY_STATUS, attribute: "delivery_status" },
        values: Object.entries(CAMPAIGN_DELIERY_STATUS_VALUE_TO_LABEL).map(([value, label]) => ({ label, value }))
    },
    [FILTER_TYPES.DSP_ADGROUP_DELIVERY_STATUS]: {
        config: { label: LABELS.DELIVERY_STATUS, attribute: "delivery_status" },
        values: Object.entries(ADGROUP_DELIVERY_STATUS_VALUE_TO_LABEL).map(([value, label]) => ({ label, value }))
    },
    [LABELS.DERIVED_GOAL]: {
        config: { label: LABELS.DERIVED_GOAL, attribute: "derived_goal" },
        values: Object.entries(DERIVED_GOAL_VALUE_TO_LABEL).map(([value, label]) => ({ label, value }))
    },
    [FILTER_TYPES.DSP_CAMPAIGN_BID_STRATEGY]: {
        config: { label: LABELS.BID_STRATEGY, attribute: "bid_strategy" },
        values: Object.entries(CAMPAIGN_BID_STRATEGY_VALUE_TO_LABEL).map(([value, label]) => ({ label, value }))
    },
    [FILTER_TYPES.DSP_ADGROUP_BID_STRATEGY]: {
        config: { label: LABELS.BID_STRATEGY, attribute: "bid_strategy" },
        values: Object.entries(ADGROUP_BID_STRATEGY_VALUE_TO_LABEL).map(([value, label]) => ({ label, value }))
    },
    [LABELS.KPI_STATUS]: {
        config: { label: LABELS.KPI_STATUS, attribute: "target_kpi_status" },
        values: Object.entries(TARGET_KPI_STATUS_CONFIGS).map(([value, { label }]) => ({ label, value }))
    },
    [LABELS.INVENTORY_TYPE]: {
        config: { label: LABELS.INVENTORY_TYPE, attribute: "inventory_type" },
        values: Object.entries(INVENTORY_TYPE_VALUE_TO_LABEL).map(([value, label]) => ({ label, value })),
    },
    [LABELS.DELIVERY_PROFILE]: {
        config: { label: LABELS.DELIVERY_PROFILE, attribute: "delivery_profile" },
        values: Object.entries(DELIVERY_PROFILE_VALUE_TO_LABEL).map(([value, label]) => ({ label, value })),
    },
    [LABELS.BASE_BID]: {
        config: { label: LABELS.BASE_BID, attribute: "base_bid" },
        values: []
    },
    [LABELS.MAX_AVERAGE_CPM]: {
        config: { label: LABELS.MAX_AVERAGE_CPM, attribute: "max_average_cpm" },
        values: []
    },
    [FILTER_TYPES.DSP_BUDGET]: {
        config: { label: LABELS.BUDGET, attribute: "budget_amount" },
        values: []
    },
    [LABELS.CATCH_UP_BOOST_PERCENTAGE]: {
        config: { label: LABELS.CATCH_UP_BOOST_PERCENTAGE, attribute: "catch_up_boost_percentage" },
        values: []
    },
    [LABELS.TARGETING_METHOD]: {
        config: { label: LABELS.TARGETING_METHOD, attribute: "targeting_method" },
        values: Object.entries(TARGETING_METHOD_VALUE_TO_LABEL).map(([value, label]) => ({ label, value })),
    },
    [LABELS.AUDIENCE_SEGMENT]: {
        config: {label: LABELS.AUDIENCE_SEGMENT, attribute: 'audience_segment'},
        values: []
    },
    [LABELS.SUPPLY_SOURCE]: {
        config: {label: LABELS.SUPPLY_SOURCE, attribute: 'supplySource'},
        values: []
    },
    [LABELS.SITE]: {
        config: {label: LABELS.SUPPLY_SOURCE, attribute: 'site'},
        values: []
    },
    [LABELS.AUDIENCE_NAME]: {
        config: {label: LABELS.AUDIENCE_NAME, attribute: 'audienceName'},
        values: []
    },
    [LABELS.AUDIENCE_TYPE]: {
        config: {label: LABELS.AUDIENCE_TYPE, attribute: 'audienceType'},
        values: [
            { label: 'Rule Based', value: 'RULE_BASED' },
            { label: 'Lookalike', value: 'LOOKALIKE' }
        ]
    },
    [LABELS.AUDIENCE_COUNT]: {
        config: {label: LABELS.AUDIENCE_COUNT, attribute: 'audienceCount'},
    },
    [LABELS.AUDIENCE_STATUS]: {
        config: {label: LABELS.AUDIENCE_STATUS, attribute: 'status'},
        values: [
            { label: 'Succeeded', value: 'SUCCEEDED' },
            { label: 'Running', value: 'RUNNING' },
            { label: 'Pending', value: 'PENDING' },
            { label: 'Deactivated', value: 'DEACTIVATED' },
            { label: 'Failed', value: 'FAILED' },
        ]
    },
    [LABELS.AUDIENCE_ADVERTISER_TYPE]: {
        config: {label: LABELS.AUDIENCE_ADVERTISER_TYPE, attribute: 'advertiserType'},
        values: [
            { label: 'Sponsored Ads', value: 'SPONSORED_ADS' },
            { label: 'Display', value: 'DISPLAY' },
        ]
    },
    [LABELS.ATC]: {
        config: { label: LABELS.ATC, attribute: 'atc' }
    },
    [LABELS.SPONSORED_ADS_INELIGIBLE]: {
        config: { label: LABELS.SPONSORED_ADS_INELIGIBLE, attribute: 'sponsored_ads_eligible' },
        values: [
            { label: 'Yes', value: 'eligible' },
            { label: 'No', value: 'ineligible' },
        ]
    }
}

const getConfigBody = (isRawFilterConfig, prefix, suffix, labelPrefix, labelSufix, whitelistedKeys) => {
    if (isRawFilterConfig)
        return {
            config: { label: `${labelPrefix} ${labelSufix}`, attribute: `${prefix}_${suffix}`.toLowerCase() },
            values: []
        }
    else
        return {
            'attribute': `${prefix}_${suffix}`.toLowerCase(),
            'numeric': true,
            'whitelistedKeys': whitelistedKeys
        }
    }  

/**
 * gets the prefixed filter config with the whitelisted keys and config with the attribute equal to the prefix + '_' + suffix for all the combination of ['current', 'previous', 'change'] and the metrices fields
 * @param {Boolean} isRawFilterConfig - if True, returns the raw filter object body with the label, attribute and value options. Else returns the filter config object with the whitelisted keys and config for the filter type
 * @param {Array} whitelistedKeys - whitelisted keys for the filter config
 * @returns {Object}
 */
export const getPrefixedFilterConfig = (whitelistedKeys=[], isRawFilterConfig=false) => {
    const prefixes = ['Current', 'Previous', 'Change']
    const metrices = [LABELS.ACOS, LABELS.SPEND, LABELS.SALES, LABELS.ORDERS, LABELS.CLICKS, LABELS.IMPRESSIONS, LABELS.CPC]
    const changeMetrices = [LABELS.CTR, LABELS.CONVERSION_RATE, LABELS.UNITS]
    const prefixedMetrics = {}
    prefixes.forEach(prefix => {
        let suffixes = (prefix !== 'Change' ? metrices.concat(changeMetrices) : metrices )
        suffixes.forEach(suffix => {
            let labelSuffix = suffix.replace(/_/g, ' ')
            let labelPrefix = prefix
            prefixedMetrics[`${labelPrefix} ${labelSuffix}`] = getConfigBody(isRawFilterConfig, prefix, RAW_FILTER_CONFIGS_DERIVED_METRIC_EXCLUDED[suffix].config.attribute, labelPrefix, labelSuffix, whitelistedKeys)
        });
    });

    return prefixedMetrics;
}

/**
 * get the prefixed filter config with the whitelisted keys and config with the attribute equal to the prefix + '_' + suffix for all the metrices fields
 * @param {String} labelPrefix prefix in the displayed label
 * @param {String} attributePrefix prefix for the attribute
 * @param {Array<String>} whitelistedKeys whitelisted keys for the filter config
 * @param {Boolean} isRawFilterConfig if True, returns the raw filter object body with the label, attribute and value options. Else returns the filter config object with the whitelisted keys and config for the filter type
 * @returns 
 */
export const getPrefixedMetricsFilterConfig = (labelPrefix, attributePrefix, whitelistedKeys = [], isRawFilterConfig = false, metrices = null) => {
    if (metrices === null) {
        metrices = [LABELS.ACOS, LABELS.SPEND, LABELS.SALES, LABELS.ORDERS, LABELS.CLICKS, LABELS.IMPRESSIONS, LABELS.CPC, LABELS.CTR, LABELS.CONVERSION_RATE, LABELS.UNITS, LABELS.ROAS]
    }
    if (['tos', 'detail', 'other'].includes(attributePrefix)) {
        metrices.push(LABELS.BASE_BIDDING_CHANGE);
    }
    const prefixedMetrics = {}
    metrices.forEach(suffix => {
        let labelSuffix = suffix.replace(/_/g, ' ')
        prefixedMetrics[`${labelPrefix} ${labelSuffix}`] = getConfigBody(isRawFilterConfig, attributePrefix, RAW_FILTER_CONFIGS_DERIVED_METRIC_EXCLUDED[suffix].config.attribute, labelPrefix, labelSuffix, whitelistedKeys)
    });
    return prefixedMetrics;
}

export const AMC_MULTI_TOUCH_ATTRIBUTION_METRICS = [LABELS.CUSTOMERS, LABELS.ORDERS, LABELS.SALES, LABELS.CONVERSION_RATE_PER_THOUSAND_IMPRESSIONS, LABELS.CONVERSION_RATE_PER_CLICK]

export const RAW_FILTER_CONFIGS = {
    ...RAW_FILTER_CONFIGS_DERIVED_METRIC_EXCLUDED,
    ...getPrefixedFilterConfig([FILTER_KEYS.CHANGE_HISTORY_PERF], true),
    ...getPrefixedMetricsFilterConfig('Top of Search', 'tos', [FILTER_KEYS.PLACEMENT], true),
    ...getPrefixedMetricsFilterConfig('Detail Page', 'detail', [FILTER_KEYS.PLACEMENT], true),
    ...getPrefixedMetricsFilterConfig('Rest of Search', 'other', [FILTER_KEYS.PLACEMENT], true),
    ...getPrefixedMetricsFilterConfig(
        "First Touch Attribution", 
        "first_touch_attribution", 
        [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS], 
        true, 
        AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
    ),
    ...getPrefixedMetricsFilterConfig(
        "Last Touch Attribution", 
        "last_touch_attribution", 
        [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS], 
        true, 
        AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
    ),
    ...getPrefixedMetricsFilterConfig(
        "Linear Touch Attribution", 
        "linear_touch_attribution", 
        [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS], 
        true, 
        AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
    ),
    ...getPrefixedMetricsFilterConfig(
        "Position Based Attribution", 
        "position_based_attribution", 
        [FILTER_KEYS.AMC_MULTI_TOUCH_ATTRIBUTION_REPORT_CAMPAIGNS], 
        true, 
        AMC_MULTI_TOUCH_ATTRIBUTION_METRICS
    ),
    ...Object.entries(DSPMetricConfigs).reduce((acc, [key, { label, attribute }]) => { 
        acc[key] = { 
            config: { label, attribute }, 
            values: [] 
        }

        return acc;
    }, {})
}

export const getRawFilterConfig = (label) => {
    return RAW_FILTER_CONFIGS[label] || DEFAULT
}

export function mergeFilters(current, { rulesetId, campaignId }) {
    let filters = []
    if (rulesetId) {
        filters.push({ attribute: FILTER_ATTRIBUTES.RULESET_ID,  operator: FILTER_OPERATORS.EQUALS, value: rulesetId })
    }
    if (campaignId) {
        filters.push({ attribute: FILTER_ATTRIBUTES.CAMPAIGN_ID, operator: FILTER_OPERATORS.EQUALS, value: campaignId })
    }
    return [...current, ...filters]
}