import React from 'react'
import AdbInput from './AdbInput'
import AdbButton from './AdbButton'

function ModalWithInput({content, setContent, onClose, onSave, disableSave, prefixSuffixSymbol, inputStyles}) {
  return (
    <div className="tw-flex tw-flex-col tw-gap-3 tw-p-3">
        <AdbInput value={content} onChange={(e) => setContent(e.target.value)} prefixSuffixSymbol={prefixSuffixSymbol} inputStyles={inputStyles} />
        <div className="tw-flex tw-gap-1.5 tw-justify-end">
        <AdbButton type="secondary" onClick={onClose}>
            Cancel
        </AdbButton>
        <AdbButton
            type={disableSave ? "secondary" : "primary"}
            disabled={disableSave}
            onClick={onSave}
        >
            Save
        </AdbButton>
        </div>
    </div>
  )
}

export default ModalWithInput