import React from "react";
import PropTypes from "prop-types";

import { NavLink } from "react-router-dom";
import AdbChevron from "../adb-icons/AdbChevron";
import { iconClasses } from "../utils/commonTailwindClasses";

function AdbButton({
  type = "primary",
  disabled = false,
  onClick = () => {},
  navLink = "",
  href = "",
  className = "",
  fullWidth = false,
  chevron,
  children,
  ...otherProps
}) {
  const btnColor = `${type === "primary" ? "tw-bg-brand-blue tw-text-white hover:tw-bg-brand-blue-dark" : ""}
                   ${type === "warning" ? "tw-bg-red-500 tw-text-white hover:tw-bg-red-600" : ""}
                   ${type === "secondary" ? "tw-bg-white tw-text-brand-text-gray tw-ring-1 tw-ring-inset tw-ring-gray-300 hover:tw-bg-gray-50" : ""}
                   ${type === "text-only" ? "tw-text-brand-text-gray" : ""}
 `;
  const chevronColor = `${type === "primary" ? "tw-stroke-white" : ""}
                        ${type === "secondary" ? "tw-stroke-brand-icon-gray" : ""}`;
  const btnWidth = `${fullWidth ? "tw-w-full" : "tw-w-fit"}`;
  const disabledStyles = `${disabled ? "tw-opacity-50 !tw-cursor-not-allowed" : ""}`;

  const style = `tw-py-2 tw-px-4 tw-text-xs tw-font-semibold hover:tw-cursor-pointer focus:!tw-outline-0 tw-rounded-md ${btnColor} ${btnWidth} ${className} ${disabledStyles}`;

  return (
    <>
      {navLink && (
        <NavLink to={navLink} className={style}>
          {children}
        </NavLink>
      )}
      {onClick && (
        <button
          className={style}
          disabled={disabled}
          onClick={onClick}
          {...otherProps}
        >
          {children}
          {chevron && <AdbChevron className={`${chevronColor} !tw-w-2.5 !tw-h-2.5 tw-ml-2 tw-mb-0.5 tw-rotate-90`} />}
        </button>
      )}
      {href && (
        <a href={href} className={style}>
          {children}
        </a>
      )}
    </>
  );
}

AdbButton.propTypes = {
  type: PropTypes.oneOf(["primary", "secondary", "warning", "text-only"]),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
  fullWidth: PropTypes.bool,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

export default AdbButton;
