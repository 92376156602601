import { useState, cloneElement } from "react";

import ReactCountryFlag from "react-country-flag";

import { getDemoStr, getReactFlagCountryCode } from "../../../Util";

import DropdownMenuItem from "../../../ui/DropdownMenuItem";
import OptionsWithSearch from "../../../ui/OptionsWithSearch";
import DropdownWithTabs from "../../../ui/DropdownWithTabs";
import { AdbTuneup } from "../../../adb-icons/AdbIcons";

import { isSharedEnv } from "../../../service";
import { iconClasses } from "../../../utils/commonTailwindClasses";

/**
 * Dropdown for selecting the current user account.
 */
function AccountSelectionDropdown({
  selectedAccountDetails,
  accounts,
  onOptionSelect,
}) {
  const [selectedOption, setSelectedOption] = useState(() => {
    return {
      value: selectedAccountDetails._id,
      label: selectedAccountDetails.account_name,
      type: selectedAccountDetails.profile_account_type,
      country_code: selectedAccountDetails.country_code,
    };
  });

  function handleOptionSelect(option) {
    setSelectedOption(option);
    onOptionSelect(option);
  }

  const settingsDropdownOptions = [
    {
      name: "Accounts Setup",
      icon: <AdbTuneup className={iconClasses} />,
      navLink: "/account_setup",
    },
  ];

  const tabsConfig = {
    ACCOUNTS: {
      name: "Accounts",
    },
    ...(!isSharedEnv && {
      SETTINGS: {
        name: "Settings",
      },
    }),
  };

  tabsConfig.ACCOUNTS.content = (
    <OptionsWithSearch
      showSearch={true}
      data={accounts.map((account) => {
        return {
          value: account._id,
          label: account.account_name,
          type: account.profile_account_type || account.type,
          country_code: account.country_code,
        };
      })}
      dataFilter={(searchTerm) => (option) =>
        option.label.toLowerCase().includes(searchTerm.toLowerCase())
      }
      selectedOption={selectedOption}
      optionRenderer={(option) => {
        return (
          <div className="tw-flex tw-w-full tw-items-center tw-gap-2.5">
            {cloneElement(
              <ReactCountryFlag
                svg
                countryCode={getReactFlagCountryCode(option.country_code)}
              />,
              {
                className:
                  "tw-object-cover tw-inline-block !tw-h-4 !tw-w-4 tw-rounded-full",
              },
            )}
            <span className="tw-text-xs">{getDemoStr(option.label, "Account")}</span>
            <span className="tw-ml-auto tw-inline-flex tw-w-16 tw-items-center tw-justify-center tw-rounded-md tw-bg-brand-sky-blue-light tw-px-2.5 tw-py-0.5 tw-text-small-tags tw-font-medium tw-capitalize tw-text-brand-blue tw-ring-1 tw-ring-inset tw-ring-brand-sky-blue-medium-light">
              {option.type}
            </span>
          </div>
        );
      }}
    />
  );

  if (tabsConfig.SETTINGS) {
    tabsConfig.SETTINGS.content = (
      <div className="tw-max-h-40 tw-overflow-y-auto tw-mt-1">
        {settingsDropdownOptions.map((option) => (
          <DropdownMenuItem key={option.id} menuItem={option} />
        ))}
      </div>
    );
  }

  const btnConfig = {
    buttonJSXDefault: "Select an account",
    buttonJSXWhenOptionSelected: (
      <div className="tw-flex tw-items-center tw-w-full">
        {cloneElement(
          <ReactCountryFlag
            svg
            countryCode={getReactFlagCountryCode(selectedOption.country_code)}
          />,
          {
            className:
              "tw-object-cover tw-inline-block !tw-h-4 !tw-w-4 tw-rounded-full",
          },
        )}
        <span className="tw-ml-2 tw-text-xs">{selectedOption.label}</span>
        <span className="tw-ml-auto tw-inline-flex tw-items-center tw-rounded-xl tw-bg-brand-sky-blue-light tw-px-2 tw-py-1 tw-text-small-tags tw-font-medium tw-capitalize tw-text-brand-blue">
          {selectedOption.type}
        </span>
      </div>
    ),
  };

  return (
    <DropdownWithTabs
      tabsConfig={tabsConfig}
      selectedOption={selectedOption}
      onOptionSelect={handleOptionSelect}
      btnConfig={btnConfig}
    />
  );
}

export default AccountSelectionDropdown;