import { signout } from './context/AuthContext';
import moment from 'moment';
import { encodedAndCompressString, get_env } from './Util';
import { appendHeaderFromAppURLPath } from './services/serviceUtils';

const getSharedURLOrigin = () => {
    return window.location.origin
}

const BASE_URL_MAP = {"brewing": "http://brewing.app.adbrew.io:8000", "development": "http://localhost:8000", "production": "https://api.adbrew.io", "shared": "https://api.adbrew.io/shared"}
const BASE_APP_URL_MAP = {"brewing": "http://brewing.app.adbrew.io", "development": "http://localhost:3000", "production": "https://app.adbrew.io", "shared": getSharedURLOrigin()}

const BASE_URL = BASE_URL_MAP["production"]
export const BASE_APP_URL = BASE_APP_URL_MAP["production"]

// const BASE_URL = "https://api.adbrew.io"
// export const BASE_APP_URL = "https://app.adbrew.io"

export const isSharedEnv = get_env() == 'shared' 

export const getFullURL = (url) => {
    if(isSharedEnv)
        return `${BASE_APP_URL}/${url}`
    return url
}

export const getAuthToken = ({ email, password, otp='', entity_id=undefined }) => {
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
    method: 'POST',
    headers: myHeaders,
    body: JSON.stringify({
        email,
        password,
        otp,
        entity_id
    }),
    redirect: 'follow'
    };

    return fetch(BASE_URL + "/token/obtain/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

// export const refreshAuthToken = () => {
//     var myHeaders = new Headers();
//     myHeaders.append("Content-Type", "application/json");
//     const { refreshToken } = JSON.parse(localStorage.getItem("credentials") || "{}");
//     var requestOptions = {
//     method: 'POST',
//     headers: myHeaders,
//     body: JSON.stringify({
//         refresh: refreshToken
//     }),
//     redirect: 'follow'
//     };

//     return fetch(BASE_URL + "/token/obtain/", requestOptions)
//             .then(handleResponse)
//             .then(data => {
//                 console.log({data});
//                 return data;
//             });
// }

const getHeaders = (notJson) => {
    var myHeaders = new Headers();
    if (!notJson) myHeaders.append("Content-Type", "application/json");
    const { accessToken } = JSON.parse(localStorage.getItem("credentials") || "{}");
    const { id } = JSON.parse(localStorage.getItem("account") || "{}");
    const mid = localStorage.getItem("mid");
    myHeaders.append("Authorization", "JWT " + accessToken);
    if (id)
        myHeaders.append("X-ACCOUNT-ID", id);
    if (mid)
        myHeaders.append("X-M-ID", mid);
    appendHeaderFromAppURLPath(myHeaders)
    return myHeaders;
}

export const signUp = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/signup/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const sendEmail = (email) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(email),
    };
    return fetch(BASE_URL + "/forgot_password/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const changePassword = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload)
    };

    return fetch(BASE_URL+ "/forgot_password/",requestOptions)
    .then(handleResponse)
    .then(data => {
        return data
    })
}

export const validate = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload)
    };

    return fetch(BASE_URL+ "/forgot_password/",requestOptions)
    .then(handleResponse)
    .then(data => {
        return data
    })
}
export const getLinkedAccounts = () => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    return fetch(BASE_URL + "/accounts/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const deleteAccount = (account_id) => {
    var requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify({account_id})
    };
    return fetch(BASE_URL + "/accounts/", requestOptions)
    .then(handleResponse)
    .then(data => {
        return data
    })

}

export const getUser = () => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    return fetch(BASE_URL + "/user/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getInvoice = async (org_id, path) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = ''
    params += org_id ? "org_id=" + org_id : "";
    params += path ? "&path=" + path : "";
    try {
        const response = await fetch(BASE_URL + "/invoices/?" + params, requestOptions);
        return response;
    } catch (error) {
      console.error('Error fetching invoice:', error);
      throw error;
    }
};

export const getDashboardData = ({ granularity, tracked_search_terms, label_prefix, report_type, campaign_ids, keyword_ids, target_ids, adgroup_ids, product_ids, search_terms, from, to, breakup, desc, count, attr, change_type, portfolio_id, campaign_id, adgroup_id, p_from, p_to, fast_query, keyword, sp_fetch_v2, campaign_labels, campaign_types, placement_ids }) => {
    let body = { granularity, tracked_search_terms, label_prefix, report_type, campaign_ids, keyword_ids, target_ids, adgroup_ids, product_ids, search_terms, from, to, breakup, desc, count, attr, change_type, portfolio_id, campaign_id, adgroup_id, p_from, p_to, keyword, sp_fetch_v2, campaign_labels, campaign_types, placement_ids }
    if (fast_query) body.fast_query = fast_query == '0' ? false : true;
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body)
    };
    let params = "/dashboard/?"
    return fetch(BASE_URL + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getAccounts = ({sp, sp_id, fetch_sp_brands, id, from, to, by_org, by_user, getMetrics=false, accountIds, page, perPage, sortColumn, sortDirection, filterSpec, filterText, sp_fetch_v2, fast_query, p_from, p_to}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = ''
    params += id ? "&id=" + id : "";
    params += by_org ? "&by_org=" + by_org : "";
    params += by_user ? "&by_user=" + by_user : "";
    params += accountIds ? "&account_ids=" + accountIds.join(",") : ""
    params += getMetrics ? "&get_metrics=1" : ""
    params += from  ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += p_from  ? "&p_from=" + p_from : "";
    params += p_to ? "&p_to=" + p_to : "";
    params += sp ? "&sp=" + sp : "";
    params += sp_id ? "&sp_id=" + sp_id : "";
    params += fetch_sp_brands ? "&fetch_sp_brands=" + fetch_sp_brands : "";
    params += sp_fetch_v2 ? "&sp_fetch_v2=1" : "";
    params += getPaginationParams({ page, perPage, sortColumn, sortDirection, filterSpec, filterText })

    return fetch(BASE_URL + "/accounts/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateAccount = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload)
    };
    return fetch(BASE_URL + "/accounts/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getAMCInstanceOptions = async (payload) => {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            action: "get_amc_instance_options_using_auth_code",
            ...payload
        })
    }

    const response = await fetch(BASE_URL + "/accounts/", requestOptions);
    const data = handleResponse(response);
    return data;
}

export const getDSPAdvertiserOptions = async (payload) => {
    const requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            action: "get_dsp_advertiser_options_using_auth_code",
            ...payload
        })
    }

    const response = await fetch(BASE_URL + "/accounts/", requestOptions);
    const data = handleResponse(response);
    return data;
}

export const getProductGroups = (productGroup_id) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    const params = productGroup_id ? "product_group_id=" + productGroup_id : "";
    return fetch(BASE_URL + "/product_groups/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getProductDetails = async ({asins=[], fetch_sku=false}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = '?'
    params += asins ? "asins=" + await encodedAndCompressString(asins.join(",")) : "";
    params += fetch_sku ? "&fetch_sku=1" : "";
    return fetch(BASE_URL + "/product_details/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const updateProductDetails = (payload, fileData) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(fileData ? true : false),
        body: payload ? JSON.stringify(payload) : fileData
    };
    return fetch(BASE_URL + "/product_details/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateProductGroup = (productGroup) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(productGroup),
    };

    return fetch(BASE_URL + "/product_groups/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getLaunchConfigs = async ({ from_date, to_date }) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "?";
    params += from_date !== undefined ? "&from_date=" + from_date : "";
    params += to_date !== undefined ? "&to_date=" + to_date : "";
    return fetch(BASE_URL + "/campaign_launcher/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}


export const getPaginationParams = ({page, perPage, sortColumn, sortDirection, filterSpec, filterText}) => {
    let params = ""
    params += page !== undefined ? `&page=${page}` : ``
    params += perPage !== undefined ? `&per_page=${perPage}` : ``
    params += sortColumn !== undefined ? `&sort_column=${sortColumn}` : ``
    params += sortDirection !== undefined ? `&sort_direction=${sortDirection}` : ``
    params += filterSpec !== undefined ? `&filter_spec=${encodeURIComponent(filterSpec)}` : ``
    params += filterText !== undefined ? `&filter_text=${encodeURIComponent(filterText)}` : ``
    return params
}

export const getCompressedPaginationParams = async ({ page, perPage, sortColumn, sortDirection, filterSpec, filterText, paramsToCompress = [] }) => {
    let params = ""

    const compressParam = async (paramName, value) => (
        paramsToCompress.includes(paramName)
            ? await encodedAndCompressString(value)
            : encodeURIComponent(value)
    );

    params += page !== undefined ? `&page=${page}` : ``
    params += perPage !== undefined ? `&per_page=${perPage}` : ``
    params += sortColumn !== undefined ? `&sort_column=${sortColumn}` : ``
    params += sortDirection !== undefined ? `&sort_direction=${sortDirection}` : ``
    params += filterSpec !== undefined ? `&filter_spec=${await compressParam('filter_spec', filterSpec)}` : ``
    params += filterText !== undefined ? `&filter_text=${await compressParam('filter_text', filterText)}` : ``

    return params
};

export const getRecommendations = async (rec_type, pg, task, days, campaignId, page, perPage, sortColumn, sortDirection, filterSpec, filterText, recTab, state, from, to, exclude_days, campaignIds, rulesetType, rulesetId) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    task = task != 0 ? task : ""
    let params = "/recommendations/?campaign_id=" + (campaignId || '') + "&product_group_id=" + pg +"&rec_type=" + rec_type + "&task=" + task + "&days=" + (days || 14) + "&exclude_days=" + (exclude_days || 0) + "&rec_tab=" + (recTab || "bid") + "&state=" + (state || "generated") + "&from=" + (from || "") + "&to=" + (to || "") + "&ruleset_type=" + (rulesetType || "") + "&ruleset_id=" + (rulesetId || "") + "&campaign_ids=" + (campaignIds || "")
    params += await getCompressedPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText, paramsToCompress:['filter_spec']})
    return fetch(BASE_URL + params, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    });
}

export const getSearchRanks = ({query, keyword, asin, from, to, rank, page, perPage, sortColumn, sortDirection, filterSpec, filterText}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += from  ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += query ? "&query=" + query : "";
    params += rank ? "&rank=" + rank : "";
    params += asin ? "&asin=" + asin : "";
    params += keyword ? "&keyword=" + keyword : "";
    return fetch(BASE_URL + '/search_ranks/?' + params, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    });
}

export const getSovKeywords = (page, perPage, sortColumn, sortDirection, filterSpec, filterText, include, exclude) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({ page, perPage, sortColumn, sortDirection, filterSpec, filterText })
    params += include ? "&include=" + include : "";
    params += exclude ? "&exclude=" + exclude : "";
    return fetch(BASE_URL + '/sov_keywords_view/?' + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const deleteSearchRankKeywords = ({keywords}) => {
    var requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify({keywords}),
    };

    return fetch(BASE_URL + "/search_ranks/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const addUpdateSearchRankKeywords = (body) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body),
    };

    return fetch(BASE_URL + "/search_ranks/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}


export const getSearchResults = ({result_type, keyword, from, to, page, perPage, sortColumn, sortDirection, filterSpec, filterText}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += "&keyword=" + encodeURIComponent(keyword)
    params += from  ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += to ? "&result_type=" + result_type : "";
    
    return fetch(BASE_URL + '/search_results/?' + params, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    });
}

export const getRelevantBrands = ({ keywords , fromDate, toDate}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "keywords=" + encodeURIComponent(keywords)
    params += "&from=" + fromDate;
    params += "&to=" + toDate;
    return fetch(BASE_URL + '/search_results/?' + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getRulesets = ({ruleset_id, type, active, accountID}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "?"
    params += ruleset_id ? "&ruleset_id=" + ruleset_id : "";
    params += type ? "&type=" + type : "";
    params += active !== undefined ? "&active=" + active : "";
    params += accountID ? "&account_id=" + accountID : "";
    return fetch(BASE_URL + "/rulesets/" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const updateChangeHistory = ({records}) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({records}),
    };

    return fetch(BASE_URL + "/change_history/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const updateAutomatedRuns = ({run_ids}) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({run_ids}),
    };

    return fetch(BASE_URL + "/change_history/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getChangeHistory = async ({changeHistoryIds, runId, rulesetIds=[], tasks = [], on_date, from_date, to_date, date, campaignIds, adGroupIds, targetIds, historyType, ruleType, targetTypes, taskType, page, perPage, sortColumn, sortDirection, filterSpec, filterText, minimal, quickFetch, getPerformanceMetrics, changeFrom, changeTo, pFrom, pTo, amazon=false }) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };

    let params = "?";
    params += getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += (tasks || []).length > 0 ? `&tasks=${tasks.join(',')}` : "";
    params += runId !== undefined ? "&run_id=" + runId : "";
    params += from_date !== undefined ? "&from_date=" + from_date : "";
    params += minimal !== undefined ? "&minimal=" + minimal : "";
    params += quickFetch !== undefined ? "&quick_fetch=" + quickFetch : "";
    params += to_date !== undefined ? "&to_date=" + to_date : "";
    params += date !== undefined ? "&on_date=" + date : "";
    params += rulesetIds ? "&ruleset_ids=" + rulesetIds.join(",") : ""
    params += targetIds ? "&target_ids=" + targetIds.join(",") : ""
    params += targetTypes ? "&target_types=" + targetTypes.join(",") : ""
    params += campaignIds ? "&campaign_ids=" + await encodedAndCompressString(campaignIds.join(",")) : ""
    // params += campaignIds ? "&campaign_ids=" + campaignIds.join(",") : ""
    params += adGroupIds ? "&adgroup_ids=" + adGroupIds.join(",") : ""
    params += historyType !== undefined ? "&history_type=" + historyType : "";
    params += ruleType !== undefined ? "&rule_type=" + ruleType : "";
    params += taskType !== undefined ? "&task_type=" + taskType : "";
    params += on_date ? "&on_date=" + on_date : ""
    params += changeHistoryIds ? "&change_history_ids=" + changeHistoryIds.join(",") : ""
    params += getPerformanceMetrics ? "&get_performance_metrics=1" : ""
    params += changeFrom !== undefined ? "&change_from=" + changeFrom : "";
    params += changeTo !== undefined ? "&change_to=" + changeTo : ""
    params += pFrom !== undefined ? "&p_from=" + pFrom : "";
    params += pTo !== undefined ? "&p_to=" + pTo : ""
    params += amazon ? "&amazon=1" : ""
    return fetch(BASE_URL + "/change_history/" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getAutomatedRuns = ({ strategyId, from_date, to_date, date, taskId, rulesetId, campaignId, adGroupId, targetIds, run_type, page, perPage, sortColumn, sortDirection, filterSpec }) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "?";
    params += getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec})
    params += from_date !== undefined ? "&from_date=" + from_date : "";
    params += to_date !== undefined ? "&to_date=" + to_date : "";
    params += date !== undefined ? "&on_date=" + date : "";
    params += taskId !== undefined ? "&task_id=" + taskId : "";
    params += rulesetId !== undefined ? "&ruleset_id=" + rulesetId : "";
    params += strategyId !== undefined ? "&strategy_id=" + strategyId : "";
    params += campaignId !== undefined ? "&campaign_id=" + campaignId : "";
    params += adGroupId !== undefined ? "&adgroup_id=" + adGroupId : "";
    params += targetIds ? "&target_ids=" + targetIds.join(",") : ""
    params += run_type ? "&run_type=" + run_type : ""
    return fetch(BASE_URL + "/automated_runs/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getDaypartingStrategies = (strategy_id, filterText, accountID) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = strategy_id ? "strategy_id=" + strategy_id : "";
    params += accountID ? "&account_id=" + accountID : "";
    params += filterText ? "&filter_text=" + filterText : "";
    return fetch(BASE_URL + "/dayparting/?" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getHourlyPerformanceData = ({ campaign_ids, placement, from_date, to_date, flag, groupByWeekday, campaign_labels, getAutoPilotData=false, minMultiplier, maxMultiplier, kpis, v2, campaignTypes }) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "?";
    params += from_date !== undefined ? "&from_date=" + from_date : "";
    params += to_date !== undefined ? "&to_date=" + to_date : "";
    params += campaign_ids !== undefined ? "&campaign_ids=" + campaign_ids : "";
    params += placement !== undefined ? "&placement=" + placement : "";
    params += flag !== undefined ? "&flag=true" : "";
    params += groupByWeekday !== undefined ? "&group_by_weekday=true" : "";
    params += campaign_labels !== undefined  ? "&labels=" + campaign_labels.join(",") : "";
    params += `&get_auto_pilot_data=${getAutoPilotData ? 1 : 0}`;
    params += minMultiplier !== undefined ? "&min_multiplier=" + minMultiplier : "";
    params += maxMultiplier !== undefined ? "&max_multiplier=" + maxMultiplier : "";
    params += kpis !== undefined ? "&kpis=" + kpis : "";
    params += v2 !== undefined ? "&v2=" + v2 : "";
    params += campaignTypes !== undefined ? "&campaign_types=" + campaignTypes : "";

    return fetch(BASE_URL + "/hourly_performance/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getStrategies = (strategy_id, accountID) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = strategy_id ? "strategy_id=" + strategy_id : "";
    params += accountID ? "&account_id=" + accountID : "";
    return fetch(BASE_URL + "/strategies/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getTasksDictParams = ({task_id, task_type, campaign_id, campaign_type, adgroup_id, active, ruleset_id, page, perPage, sortColumn, sortDirection, filterSpec, filterText, get_account_wise_count}) => {
    return getTasks(task_id, task_type, campaign_id, campaign_type, adgroup_id, active, ruleset_id, page, perPage, sortColumn, sortDirection, filterSpec, filterText, get_account_wise_count)
}

export const getTasks = (task_id, task_type, campaign_id, campaign_type, adgroup_id, active, ruleset_id, page, perPage, sortColumn, sortDirection, filterSpec, filterText, get_account_wise_count) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "?";
    params += getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += task_id ? "&task_id=" + task_id : "";
    params += task_type ? "&task_type=" + task_type : "";
    params += campaign_id ? "&campaign_id=" + campaign_id : "";
    params += adgroup_id ? "&adgroup_id=" + adgroup_id : "";
    params += campaign_type ? "&campaign_type=" + campaign_type : "";
    params += ruleset_id ? "&ruleset_id=" + ruleset_id : "";
    params += active ? "&active=1" : "";
    params += get_account_wise_count ? "&get_account_wise_count=1" : "";
    return fetch(BASE_URL + "/tasks/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getProducts = ({campaignId, from, to, no_cache, campaign_only, no_metrics}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = '';
    params += from ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += campaign_only ? "&campaign_only=" + campaign_only : "";
    params += no_metrics ? "&no_metrics=" + no_metrics : "";
    return fetch(BASE_URL + "/products/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getProductsV2 = ({no_metrics_filter, group_by, asins=[], skus=[], campaignId, from, to, no_cache, campaign_only, no_metrics, page, perPage, sortColumn, sortDirection, filterSpec, filterText, fastQuery, sp_fetch_v2, get_retail_performance}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += from ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += campaign_only ? "&campaign_only=" + campaign_only : "";
    params += no_metrics ? "&no_metrics=" + no_metrics : "";
    params += asins ? `&asins=${asins.join(',')}` : "";
    params += skus ? `&skus=${encodeURIComponent(skus.join(','))}` : "";
    params += no_metrics_filter ? `&no_metrics_filter=${no_metrics_filter}` : "";
    params += group_by ? `&group_by=${group_by}` : "";
    params += fastQuery ? `&fast_query=${fastQuery}`: "";
    params += sp_fetch_v2 ? `&sp_fetch_v2=1`: "";
    params += get_retail_performance ? `&get_retail_performance=1`: "";
    return fetch(BASE_URL + "/products_v2/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getTargets = ({campaignId, from, to, no_cache, change_history_ids, keywords_ids, target_ids, campaign_only}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };

    let params = '';
    params += from ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += campaign_only ? "&campaign_only=" + campaign_only : "";
    params += change_history_ids ? "&change_history_ids=" + change_history_ids.join(",") : "";
    params += keywords_ids ? "&keyword_ids=" + keywords_ids.join(",") : "";
    params += target_ids ? "&target_ids=" + target_ids.join(",") : "";
    return fetch(BASE_URL + "/targets/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getTargetsV2 = async ({campaignId, from, keys, target_type, group_by, to, no_cache, change_history_ids, keywords_ids, target_ids, campaign_only, page, perPage, sortColumn, sortDirection, filterSpec, filterText, fastQuery, fetchAggregatedTargets, no_metrics_filter}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };

    let params = await getCompressedPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText, paramsToCompress:['filter_spec']})
    params += from ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += campaign_only ? "&campaign_only=" + campaign_only : "";
    params += change_history_ids ? "&change_history_ids=" + change_history_ids.join(",") : "";
    params += keywords_ids ? "&keyword_ids=" + keywords_ids.join(",") : "";
    params += target_ids ? "&target_ids=" + target_ids.join(",") : "";
    params += group_by ? `&group_by=${group_by}` : "";
    params += target_type ? `&target_type=${target_type}` : "";
    params += fastQuery ? `&fast_query=1` : "";
    params += fetchAggregatedTargets ? `&fetch_aggregated_targets=1` : "";
    params += no_metrics_filter ? `&no_metrics_filter=${no_metrics_filter}` : "";
    if (keys) {
        const compressedKeys = await encodedAndCompressString(JSON.stringify(keys))
        params += `&keys=${compressedKeys}`
    }
    return fetch(BASE_URL + "/targets_v2/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getNegativeTargets = ({campaignId, no_cache, target_type, page, perPage, filterSpec, filterText}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, filterSpec, filterText})
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += target_type? "&target_type=" + target_type: "";
    return fetch(BASE_URL + "/negative_targets/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const updateNegativeTargets = (payload) => {
    var requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/negative_targets/", requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    });

}

export const getAdGroups = ({campaignId, no_cache, from, to, campaign_type, adg_list}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = '';
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += from  ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += campaign_type ? "&campaign_type=" + campaign_type : "";
    params += adg_list ? "&adg_list=" + adg_list : "";
    return fetch(BASE_URL + "/adgroups/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}


export const updateAdGroups = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload)
    };
    return fetch(BASE_URL + "/adgroups/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getPlacements = ({campaign_type, campaignId, no_cache, noMetrics, from, to, page, perPage, sortColumn, sortDirection, filterSpec, filterText}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += campaign_type ? "&campaign_type=" + campaign_type : "";
    params += campaignId ? "&campaign_id=" + campaignId : "";
    params += from  ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += noMetrics ? "&no_metrics=1" : "";
    return fetch(BASE_URL + "/placements/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createReport = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/reports/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const editSavedFilters = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/user/", requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const updateProduct = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/products/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getAsinReporting = ({asin, from_date, to_date, typ}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "";
    params += from_date ? "&from=" + from_date : "";
    params += to_date ? "&to=" + to_date : "";
    params += typ ? "&type=" + typ : "";
    return fetch(`${BASE_URL}/products/${asin}/reporting?${params}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getAsinHistoryReporting = ({asin, from_date, to_date, type}) => {
    var requestOptions = {
            method: 'GET',
            headers: getHeaders()
    };
    let params = "";
    params += from_date ? "&from=" + from_date : "";
    params += to_date ? "&to=" + to_date : "";
    params += type ? "&type=" + type : "";
    return fetch(`${BASE_URL}/product_details/${asin}/reporting?${params}`, requestOptions)
                    .then(handleResponse)
                    .then(data => {
                            return data;
                    });
}

export const getSOV = ({keyword, from_date, to_date, placement, viewType}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "";
    params += from_date ? "&from=" + from_date : "";
    params += to_date ? "&to=" + to_date : "";
    params += placement ? "&placement=" + placement : "";
    params += viewType ? "&viewType=" + viewType : "";
    return fetch(`${BASE_URL}/sov/${keyword}/?${params}`, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getPortfolios = (no_cache) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = no_cache ? "&no_cache=" + no_cache : "";
    return fetch(BASE_URL + "/portfolios/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getCampaigns = async (campaign_id, campaign_type, extended, from, to, no_cache, page, perPage, sortColumn, sortDirection, filterSpec, filterText, noMetrics, daypartingStrategyId, ruleset_id, change_type, fastQuery, newQuery, get_adg, include, exclude, excludeAdgs, filterServingStatus, get_budget_usage=false, project_fields=[], noAdgs=false) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += get_adg ? "&get_adg=true": "";
    params += campaign_id ? "&campaign_id=" + campaign_id : "";
    params += include ? "&include=" + include : "";
    params += exclude ? "&exclude=" + exclude : "";
    params += excludeAdgs ? "&exclude_adgs=" + await encodedAndCompressString(excludeAdgs.join(",")) : "";
    params += campaign_type ? "&campaign_type=" + campaign_type : "";
    params += extended ? "&extended=1" : "";
    params += from ? "&from=" + from : "";
    params += to ? "&to=" + to : "";
    params += no_cache ? "&no_cache=" + no_cache : "";
    params += noMetrics ? "&no_metrics=1" : "";
    params += daypartingStrategyId ? "&dayparting_strategy_id=" + daypartingStrategyId : "";
    params += ruleset_id ? "&campaign_ruleset_id=" + ruleset_id : "";
    params += change_type ? "&ruleset_change_type=" + change_type : "";
    params += fastQuery ? "&fast_query=" + fastQuery : "";
    params += newQuery ? "&new_query=" + newQuery : "";
    params += get_budget_usage ? "&get_budget_usage=" + get_budget_usage : "";
    params += filterServingStatus ? "&filter_serving_status=" + filterServingStatus.join(",") : "";
    params += project_fields && project_fields.length > 0 ? "&project_fields=" + project_fields : "";
    params += noAdgs ? "&no_adgs=1" : ""; 
    return fetch(BASE_URL + "/campaigns/?" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const updateCampaign = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/campaigns/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getSearchTerms = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/search_terms/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getKeywords = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/keywords/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const updateTargets = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/targets_v2/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const applyRecommendations = (body) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(body)
    };

    return fetch(BASE_URL + "/recommendations/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateRuleset = (ruleset) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(ruleset),
    };

    return fetch(BASE_URL + "/rulesets/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateDaypartingStrategy = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload),
    };

    return fetch(BASE_URL + "/dayparting/", requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const createUpdateStrategy = (strategy) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(strategy),
    };

    return fetch(BASE_URL + "/strategies/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateTask = (strategy) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(strategy),
    };

    return fetch(BASE_URL + "/tasks/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const deleteTasks = (params) => {
    var requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify(params),
    };

    return fetch(BASE_URL + "/tasks/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const deleteRuleset = (id) => {
    var requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify({ruleset_id: id}),
    };
    return fetch(BASE_URL + "/rulesets/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

function handleResponse(response) {
    return response.text().then(text => {
        try {
            const data = text && JSON.parse(text);
            if (!response.ok) {
                if (response.status === 401) {
                    signout();
                }
                if (isSharedEnv && response.status === 403){
                    if(data && data.redirect)
                        window.location.href = `${data.redirect}${window.location.pathname}${window.location.search}`
                    else {
                        if (data.message) {
                            alert(data.message)
                        }
                        signout();
                    }
                }
                if (response.status === 400) {
                    const error = (data && data.error) || 'Bad Request';
                    return Promise.reject(error);
                }
                const error = (data && data.message) || response.statusText;
                return Promise.reject(error);
            }
            return data;
        } catch (err) {
            return Promise.reject(err);
        }
    });
}

export function getFormattedDate(date) {
    return moment(date).format("MM/DD/YYYY")
}

export const getTerms = (asin, page, perPage, sortColumn, sortDirection, filterSpec, filterText) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
        params += asin ? "&asin=" + asin : "";
    return fetch(BASE_URL + "/terms/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateTerm = (term) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(term),
    };

    return fetch(BASE_URL + "/terms/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const deleteTerms = (selectedTerms) => {
    var requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify({selected_terms: selectedTerms}),
    };

    return fetch(BASE_URL + "/terms/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateTargets = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload)
    };
    return fetch(BASE_URL + "/targets/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createLaunch = (camapignLaunchInfo) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(camapignLaunchInfo),
    };
    return fetch(BASE_URL + "/campaign_launcher/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getSuggestions = (suggestionInfo) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(suggestionInfo),
    };

    return fetch(BASE_URL + "/suggestions/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getOrganization = ({extended}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "";
    params += extended ? "&extended=" + true : "";
    return fetch(BASE_URL + "/organization/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const createUpdateProducts = (productsInfo) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(productsInfo),
    };

    return fetch(BASE_URL + "/products/", requestOptions)
            .then(handleResponse)
                    .then(data => {
                        return data;
                    });
}

export const updateOrg = (invite) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(invite),
    };

    return fetch(BASE_URL + "/organization/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const updateUser = (payload) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(payload)
    };
    return fetch(BASE_URL + "/user/", requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getLabels = (type) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify(type)
    };
    return fetch(BASE_URL + "/labels/", requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getSupportData = ({location, searchText, documentation=false}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "";
    params += location ? "&location=" + location : "";
    params += searchText ? "&search_text=" + searchText : "";
    params += documentation ? "&documentation=" + documentation : "";
    return fetch(BASE_URL + "/support/?" + params, requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const postProductGoal = (body) => {
    var requestOptions = {
      method: "POST",
      headers: getHeaders(),
      body: JSON.stringify(body)
    };
    return fetch(BASE_URL + "/product_goals/", requestOptions)
      .then(handleResponse)
      .then((data) => {
        return data;
      });
  }

export const getProductGoals = (page, perPage, sortColumn, sortDirection, filterSpec, filterText, frequency) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = "/product_goals/?"
    params += getPaginationParams({page, perPage, sortColumn, sortDirection, filterSpec, filterText})
    params += frequency ? "&frequency=" + frequency : "";
    return fetch(BASE_URL + params, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    });
}

export const deleteProductGoals = (selectedGoals) => {
    var requestOptions = {
        method: 'DELETE',
        headers: getHeaders(),
        body: JSON.stringify({selected_goals: selectedGoals}),
    };

    return fetch(BASE_URL + "/product_goals/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}

export const getSchedule = ({period, ruleset_ids=[], strategy_ids=[], campaign_ids=[]}) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = `/schedule/?`
    params += period ? `&period=${period}` : "";
    params += ruleset_ids.length != 0 ? `&ruleset_ids=${ruleset_ids.join(',')}` : "";
    params += strategy_ids.length != 0 ? `&strategy_ids=${strategy_ids.join(',')}` : "";
    params += campaign_ids.length != 0 ? `&campaign_ids=${campaign_ids.join(',')}` : "";
    return fetch(BASE_URL + params, requestOptions)
    .then(handleResponse)
    .then(data => {
        return data;
    });
}

export const getAudit = ({ auditType, from_date, to_date, page, perPage, sortColumn, sortDirection, filterSpec, filterText }) => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    let params = `/audit/?`
    params += getPaginationParams({ page, perPage, sortColumn, sortDirection, filterSpec, filterText })
    params += auditType ? `&audit_type=${auditType}` : "";
    params += from_date !== undefined ? "&from=" + from_date : "";
    params += to_date !== undefined ? "&to=" + to_date : "";
    return fetch(BASE_URL + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const uploadCampaignFileData = (fileData = undefined) => {
    var requestOptions = {
        method: 'POST',
        headers: getHeaders(fileData ? true : false),
        body: fileData
    };

    return fetch(BASE_URL + "/campaigns/", requestOptions)
            .then(handleResponse)
            .then(data => {
                return data;
            });
}


export const retryLauchConfigs = (launchIds, retry_type) => {
    var requestOptions = {
        method: 'PUT',
        headers: getHeaders(),
    };

    let params = '?'
    params += `launch_ids=${launchIds.join(",")}`
    params += `&retry_type=${retry_type}`
    return fetch(BASE_URL + "/campaign_launcher/" + params, requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}

export const getAudiences = () => {
    var requestOptions = {
        method: 'GET',
        headers: getHeaders()
    };
    return fetch(BASE_URL + "/amc_audiences/", requestOptions)
        .then(handleResponse)
        .then(data => {
            return data;
        });
}