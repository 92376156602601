import React from 'react'

function AdbChevron(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="6" height="11" viewBox="0 0 6 11" fill="none" {...props}>
        <path d="M1.33301 9.8667L5.14967 5.69935L1.33301 1.53337" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  )
}

export default AdbChevron