import { AdbBarLineChart, AdbBookClosed, AdbBulb, AdbCpuChip, AdbCreditCard, AdbEmailMessage, AdbFile, AdbHome, AdbLayout, AdbMessageDots, AdbRocket, AdbSettings } from "./adb-icons/AdbIcons";
import AdbUsersMultiple from "./adb-icons/AdbUsersMultiple";
import { PAGE_PATHS } from "./constants/routeConstants";

/**
 * NOTE: Add an entry to PAGE_PATHS for each page route 
 * and use this constant instead of hardcoded string
 */

const config = {
    items: [
        {
            id: 'sp_ads',
            title: 'Sponsored Ads',
            type: 'group',
            children: [
                {
                    id: 'dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dashboard',
                    icon: <AdbHome/>
                },
                {
                    id: 'sp_campaign_management',
                    title: 'Campaign Management',
                    type: 'collapse',
                    icon: <AdbLayout/>,
                    children: [
                        {
                            id: 'campaigns',
                            title: 'Campaign Manager',
                            type: 'item',
                            url: '/campaigns',
                            data: {
                                step: 'campaigns'
                            },      
                            icon: 'feather icon-flag',
                        },
                        {
                            id: 'placement',
                            title: 'Placements',
                            type: 'item',
                            url: '/placement',
                            icon: 'feather icon-layers'
                        },
                        {
                            id: 'Negative Targets',
                            title: 'Negative Targets',
                            type: 'item',
                            url: '/negative_targets',
                            icon: 'feather icon-minus-circle'
                        },
                        {
                            id: 'products',
                            title: 'Product Explorer',
                            type: 'item',
                            url: '/products',
                            icon: 'feather icon-package',
                            data: {
                                step: 'product-explore'
                            },
                        },
                        {
                            id: 'targets',
                            title: 'Targets Analyser',
                            type: 'item',
                            url: '/targets',
                            data: {
                                step: 'target-analyzer'
                            },
                            icon: 'feather icon-target',
                        },
                        {
                            id: 'search_terms',
                            title: 'Search Terms',
                            type: 'item',
                            url: '/search_terms',
                            data: {
                                step: 'search-terms'
                            },
                            icon: 'feather icon-search',
                        }
                    ]
                },
                {
                    id: 'insights',
                    title: 'Insights',
                    type: 'collapse',
                    icon: <AdbBulb/>,
                    children: [
                        {
                            id: 'audit',
                            title: 'Audit',
                            // superscript: '(Beta)',
                            type: 'item',
                            url: '/audit',
                            icon: 'feather icon-activity',
                        },
                        {
                            id: 'recommendations',
                            title: 'Smart Recommendations',
                            type: 'item',
                            data: {
                                step: 'rec'
                            },
                            url: '/smart_recommendations',
                            icon: 'feather icon-radio',
                        }
                    ]
                },
                {
                    id: 'automation',
                    title: 'Automation',
                    type: 'collapse',
                    icon: <AdbCpuChip/>,
                    children: [
                        {
                            id: 'rulesets',
                            title: 'Rule Sets',
                            type: 'item',
                            data: {
                                step: 'rulesets'
                            },
                            url: '/rulesets',
                            icon: 'feather icon-server',
                        },
                        {
                            id: 'target_management',
                            title: 'Target Movement',
                            type: 'item',
                            data: {
                                step: 'target-management'
                            },
                            url: '/target_management',
                            icon: 'feather icon-check-square',
                        },
                        {
                            id: 'dayparting',
                            title: 'Dayparting',
                            type: 'item',
                            url: '/dayparting',
                            icon: 'feather icon-clock',
                        },
                        {
                            id: 'change_history',
                            title: 'Change History',
                            type: 'item',
                            data: {
                                step: 'change-history'
                            },
                            url: '/change_history',
                            icon: 'feather icon-refresh-cw'
                        }
                    ]
                },
                {
                    id: 'sp_campaign_launcher',
                    title: 'Campaign Launcher',
                    type: 'collapse',
                    icon: <AdbRocket/>,
                    children: [
                        {
                            id: 'campaign_strategies',
                            title: 'Campaign Strategies',
                            type: 'item',
                            url: '/strategies',
                            icon: 'feather icon-repeat',
                        },
                        {
                            id: 'campaign_launcher',
                            title: 'Campaign Launcher',
                            type: 'item',
                            url: '/campaign_launcher',
                            icon: 'fa fa-play-circle'
                        },
                    ]
                },
                {
                    id: 'analytics',
                    title: 'Analytics',
                    type: 'collapse',
                    icon: <AdbBarLineChart/>,
                    children: [
                        {
                            id: 'search_rank_sov',
                            title: 'Search Rank & SOV',
                            type: 'item',
                            url: '/search_ranks',
                            icon: 'feather icon-award',
                        },
                        {
                            id: 'brand_analytics',
                            title: 'Brand Analytics',
                            type: 'item',
                            url: '/brand_analytics',
                            icon: 'feather icon-anchor',
                            badge: {
                                variant: 'info',
                                text: 'BETA'
                            },
                        },
                        {
                            id: 'product_goals',
                            title: 'Product Goals',
                            type: 'item',
                            url: '/product_goals',
                            icon: 'feather icon-circle'
                        },
                        {
                            id: 'n_grams',
                            title: 'N-Grams',
                            type: 'item',
                            url: '/n_grams',
                            icon: 'feather icon-search',
                            badge: {
                                variant: 'warning',
                                text: 'NEW'
                            },
                        }
                        // {
                        //     id: 'reports',
                        //     title: 'Custom Reports',
                        //     type: 'item',
                        //     data: {
                        //         step: 'reports'
                        //     },
                        //     url: '/reports',
                        //     icon: 'feather icon-file-text'
                        // }
                    ]
                },
                {
                    id: 'settings',
                    title: 'Settings',
                    type: 'collapse',
                    icon: <AdbSettings/>,
                    children: [
                        {
                            id: 'shared_links',
                            title: 'Shared Links',
                            type: 'item',
                            url: PAGE_PATHS.SHARED_LINKS,
                            icon: 'feather icon-link-2',
                        },
                        {
                            id: 'account_setup',
                            title: 'Account Setup',
                            type: 'item',
                            url: '/account_setup',
                            icon: 'feather icon-settings',
                        },
                        {
                            id: 'term',
                            title: 'Brand Terms',
                            type: 'item',
                            url: '/terms',
                            icon: 'feather icon-clipboard',
                        }
                    ]
                }
            ]
        },
        // {
        //     id: 'settings',
        //     title: 'Settings',
        //     type: 'group',
        //     icon: 'icon-charts',
        //     children: [
        //         {
        //             id: 'alerts',
        //             title: 'Alerts',
        //             type: 'item',
        //             icon: 'feather icon-alert-triangle',
        //             classes: 'disabled',
        //             url: ''
        //         },
        //         {
        //             id: 'budget',
        //             title: 'Monthly Budget',
        //             type: 'item',
        //             icon: 'feather icon-shopping-cart',
        //             classes: 'disabled',
        //             url: ''
        //         }
        //     ]
        // },
        {
            id: 'dsp',
            title: 'DSP',
            badge: {
                variant: 'info',
                text: 'BETA'
            },
            type: 'group',
            children: [
                {
                    id: 'dsp_dashboard',
                    title: 'Dashboard',
                    type: 'item',
                    url: '/dsp/dashboard',
                    icon: <AdbHome/>,
                },
                {
                    id: 'dsp_campaign_management',
                    title: 'Campaign Management',
                    type: 'collapse',
                    icon: <AdbCreditCard/>,
                    children: [
                        {
                            id: 'dsp_campaigns',
                            title: 'Campaign Manager',
                            type: 'item',
                            url: '/dsp/campaigns',
                            icon: 'feather icon-flag',
                        },
                        {
                            id: 'dsp_adgroups',
                            title: 'Adgroup Manager',
                            type: 'item',
                            url: '/dsp/adgroups',
                            icon: 'fa fa-th',
                        },
                        {
                            id: 'dsp_products',
                            title: 'Product Explorer',
                            type: 'item',
                            url: '/dsp/products',
                            icon: 'feather icon-package',
                        },
                        {
                            id: 'dsp_audiences',
                            title: 'Audiences Explorer',
                            type: 'item',
                            url: '/dsp/audiences',
                            icon: 'feather icon-users',
                        },
                        {
                            id: 'dsp_inventory',
                            title: 'Inventory Explorer',
                            type: 'item',
                            url: '/dsp_inventory',
                            icon: 'fa fa-map-marker',
                        }
                    ]
                }
            ]
        },
        {
            id: 'amc',
            title: 'AMC',
            badge: {
                variant: 'info',
                text: 'BETA'
            },
            type: 'group',
            children: [
                {
                    id: 'amc_reports',
                    title: 'AMC Reports',
                    type: 'item',
                    url: '/amc/reports',
                    icon: <AdbFile/>,
                },
                {
                    id: 'amc_audiences',
                    title: 'AMC Audiences',
                    type: 'item',
                    url: '/amc/audiences',
                    icon: <AdbUsersMultiple/>,
                }
            ]
        },
        {
            id: 'support',
            title: 'Support',
            type: 'group',
            children: [
                {
                    id: 'kb',
                    title: 'Knowledge Base',
                    type: 'item',
                    icon: <AdbBookClosed/>,
                    url: 'https://help.adbrew.io/',
                    external: true,
                },
                {
                    id: 'support_portal',
                    title: 'Report an Issue',
                    type: 'item',
                    icon: <AdbEmailMessage/>,
                    url: 'https://adbrew.atlassian.net/servicedesk/customer/portals',
                    external: true,
                }
                // {
                //     id: 'need_support',
                //     title: 'Need Support?',
                //     type: 'item',
                //     icon: 'feather icon-help-circle',
                //     url: '/support'
                // }
            ]
        }
    ]
};
export default config;