import React from "react";

function AdbIconPencil(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      {...props}
    >
      <path
        d="M12.0002 1.33331L14.6668 3.99998M1.3335 14.6666L2.18443 11.5466C2.23995 11.343 2.2677 11.2412 2.31032 11.1463C2.34815 11.062 2.39464 10.9819 2.44904 10.9073C2.51031 10.8232 2.5849 10.7486 2.7341 10.5994L9.62304 3.71044C9.75504 3.57843 9.82105 3.51243 9.89716 3.4877C9.9641 3.46595 10.0362 3.46595 10.1032 3.4877C10.1793 3.51243 10.2453 3.57843 10.3773 3.71044L12.2897 5.62286C12.4217 5.75486 12.4877 5.82086 12.5124 5.89697C12.5342 5.96392 12.5342 6.03604 12.5124 6.10299C12.4877 6.17909 12.4217 6.2451 12.2897 6.3771L5.40077 13.266C5.25157 13.4152 5.17697 13.4898 5.09288 13.5511C5.01822 13.6055 4.93811 13.652 4.85384 13.6898C4.75893 13.7324 4.65715 13.7602 4.45359 13.8157L1.3335 14.6666Z"
        stroke-width="1.33333"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
}

export default AdbIconPencil;