import React, { useContext, createContext, useState, useEffect, useRef } from "react";
import moment from "moment";
import { useFilter } from "./FilterContext";
import { accountContext } from "./AccountContext";

const tablePreferencesContext = createContext();

function useTablePreferences() {
    return useContext(tablePreferencesContext);
}

function ProvideTablePreferences({ children }) {
    const tablePreferences = useProvideTablePreferences();
    return (
        <tablePreferencesContext.Provider value={tablePreferences}>
            {children}
        </tablePreferencesContext.Provider>
    )
}

const DEFAULT_FILTER_PREF = {
    perPage: 10,
    filterText: '',
    productGoalsFrequency: 'weekly',
}

const GENERAL_PREF_LIST = {
    SHOW_FLAT_TARGETS_VIEW: "showFlatTargetsView",
}

function useProvideTablePreferences() {

    const [page, setPage] = useState(1)
    const [sortDetails, setSortDetails] = useState({})
    const { setFilterType, filters, filterKey } = useFilter()
    const [userPreference, setUserPreference] = useState({})
    const [showEditColumnsModal, setShowEditColumnsModal] = useState(false)
    const [columnPreferences, setColumnPreferences] = useState()
    const [newColumnPreferences, setNewColumnPreferences] = useState()
    const [expandPreferences, setExpandPreferences] = useState({})
    const [generalPreferences, setGeneralPreferences] = useState({})
    const {selectedAccountDetails = {}} = useContext(accountContext);

    const getScopedFilterKey = (k) => {
        return `${selectedAccountDetails.id}:${k}`
    }
    // Whenever parent changes, use the pagination preference for the same from local storage.
    useEffect(() => {
        const userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}
        setExpandPreferences(userPrefRaw.expandPreferences ?? {})
        setGeneralPreferences(userPrefRaw.generalPreferences ?? {})
        const scopedFilterKey = getScopedFilterKey(filterKey)
        if (filterKey && userPrefRaw[filterKey]) {
            setColumnPreferences(userPrefRaw[filterKey].columnPreferences)
            setNewColumnPreferences(userPrefRaw[filterKey].newColumnPreferences)
        } else {
            setColumnPreferences() // reset column preferences, otherwise the older one gets retained
            setNewColumnPreferences()
        }
        if (scopedFilterKey && userPrefRaw[scopedFilterKey]) {
            setUserPreference(userPrefRaw[scopedFilterKey].filterPagination || DEFAULT_FILTER_PREF)
            setPreferenceToLocal(userPrefRaw[scopedFilterKey].filterPagination || DEFAULT_FILTER_PREF)
        }
        else {
            setUserPreference(DEFAULT_FILTER_PREF)
            setPreferenceToLocal(DEFAULT_FILTER_PREF)
        }
    }, [filterKey])

    const setPreferenceToLocal = (filterPagination, columnPreferences, expandPreferences, generalPreferences, newColumnPreferences) => {
        let userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}
        if (expandPreferences) {
            userPrefRaw.expandPreferences = expandPreferences
        }
        if (generalPreferences) {
            userPrefRaw.generalPreferences = generalPreferences
        }
        if (!filterKey) {
            localStorage.setItem('userPreference', JSON.stringify(userPrefRaw))
            return;
        }
        userPrefRaw[filterKey] = userPrefRaw[filterKey] || {}
        userPrefRaw[getScopedFilterKey(filterKey)] = userPrefRaw[getScopedFilterKey(filterKey)] || {}
        if (filterPagination) {
            userPrefRaw[getScopedFilterKey(filterKey)].filterPagination = filterPagination
        }
        if (columnPreferences) {
            userPrefRaw[filterKey].columnPreferences = columnPreferences
        }
        if (newColumnPreferences) {
            userPrefRaw[filterKey].newColumnPreferences = newColumnPreferences
        }
        localStorage.setItem('userPreference', JSON.stringify(userPrefRaw))
    }

    const getPreferences = (par) => {
        const k = getScopedFilterKey(par)
        let userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}
        userPrefRaw[k] = userPrefRaw[k] || {}
        if (userPrefRaw[k].filterPagination)
            userPrefRaw[k].filterPagination = { ...DEFAULT_FILTER_PREF, ...userPrefRaw[k].filterPagination }
        else
            userPrefRaw[k].filterPagination = DEFAULT_FILTER_PREF
        return userPrefRaw[k].filterPagination
    }

    const setFilterText = (text) => {
        setUserPreference(t => ({ ...t, filterText: text }))
        setPreferenceToLocal({ ...userPreference, filterText: text })
    }

    const setProductGoalsFrequency = (productGoalsFrequency) => {
        setUserPreference(t => ({ ...t, productGoalsFrequency }))
        setPreferenceToLocal({ ...userPreference, productGoalsFrequency })
    }

    const handlePageChange = page => {
        setPage(page)
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        // setPerPage(newPerPage);
        setUserPreference(t => ({ ...t, perPage: newPerPage }))
        setPreferenceToLocal({ ...userPreference, perPage: newPerPage })
        // setPreferenceToLocal('perPage', newPerPage)
    };

    const handleSort = async (column, sortDirection) => {
        setSortDetails({ column: column.sortField, direction: sortDirection })
    };

    useEffect(() => {
        setPreferenceToLocal(undefined, undefined, undefined, generalPreferences)
    }, [generalPreferences])

    useEffect(() => {
        setPreferenceToLocal(undefined, columnPreferences)
    }, [columnPreferences])

    useEffect(() => {
        setPreferenceToLocal(undefined, undefined, undefined, undefined, newColumnPreferences)
    }, [newColumnPreferences])

    useEffect(() => {
        setPreferenceToLocal(undefined, undefined, expandPreferences)
    }, [expandPreferences])

    const isExpanded = (key) => {
        return expandPreferences ? expandPreferences[key] ?? true : false
    }

    const updateExpandPreference = (key, value) => { 
        if(!expandPreferences) return
        expandPreferences[key] = value
        setExpandPreferences({ ...expandPreferences })
    }

    const updateGeneralPreferences = (key, value) => {
        if (!generalPreferences) return
        generalPreferences[key] = value
        setGeneralPreferences({ ...generalPreferences })
    }

    const getGeneralPreference = (key) => {
        return generalPreferences ? generalPreferences[key] : undefined
    }

    const updateColumnPreferences = (preferences) => {
        setColumnPreferences(preferences)
    }

    const updateNewColumnPreferences = (preferences) => {
        setNewColumnPreferences(preferences)
    }


    const getColumnsFromPreferences = (columns) => {
        if (columnPreferences) {
            let orderedColumns = []
            for (let col of columns) {
                if (col.alwaysShow) {
                    orderedColumns.push(col)
                }
            }
            for (let prop of columnPreferences) {
                let matchedCol = columns.find(col => col.colId == prop.colId)
                if (matchedCol) {
                    orderedColumns.push(matchedCol)
                }
            }
            return orderedColumns
        }
        return columns.filter(col => !col.hidden_by_default)
    }

    const getNewColumnPreferences = (filterKey) => {
        let userPrefRaw = JSON.parse(localStorage.getItem('userPreference')) || {}

        const preferences = userPrefRaw[filterKey]?.newColumnPreferences || {}
        setColumnPreferences(preferences);

        return preferences;
    }

    const closeEditColumnsModal = () => {
        setShowEditColumnsModal(false)
    }

    const openEditColumnsModal = () => {
        setShowEditColumnsModal(true)
    }


    return {
        page, sortDetails, getPreferences,
        handlePageChange, handlePerRowsChange, handleSort, setFilterText,
        setFilterType, filters,
        columnPreferences,
        newColumnPreferences,
        userPreference,
        updateColumnPreferences,
        updateNewColumnPreferences,
        getColumnsFromPreferences,
        getNewColumnPreferences,
        showEditColumnsModal,
        closeEditColumnsModal,
        openEditColumnsModal,
        isExpanded,
        updateExpandPreference,
        expandPreferences,
        setProductGoalsFrequency,
        getGeneralPreference,
        updateGeneralPreferences
    }
}

export {
    useTablePreferences,
    ProvideTablePreferences,
    GENERAL_PREF_LIST
}