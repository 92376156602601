import { Listbox, ListboxButton, ListboxOption, ListboxOptions } from '@headlessui/react'
import React from 'react'
import OpenCloseTransition from './OpenCloseTransition'
import { selectBoxOption, selectBoxOptions } from '../utils/commonTailwindClasses'
import { AdbChevronUpDown } from '../adb-icons/AdbIcons'
 
function AdbListBox({value, onChange, children}) {
    return <Listbox value={value} onChange={onChange}>
        {children}
    </Listbox>
}

function AdbListBoxButton({ showDefaultIcon=true, defaultIconStyles, buttonStyles, children}) {
    return (
        <ListboxButton className={`tw-inline-flex tw-items-center tw-justify-between tw-text-xs hover:tw-cursor-pointer focus:!tw-outline-0 tw-rounded-md tw-border tw-border-gray-300 tw-px-3 tw-text-gray-500 tw-bg-white hover:tw-bg-gray-50 ${buttonStyles}`}>
            {children}
            {showDefaultIcon && <AdbChevronUpDown className={`tw-ml-3 tw-h-4 tw-w-4 tw-stroke-brand-icon-gray ${defaultIconStyles}`} />}
        </ListboxButton>
    )
}

function AdbListBoxOptions({options, selectedOption, position="bottom end", customStyles, children}) {
    return (
        <OpenCloseTransition >
            <ListboxOptions
                anchor={position}
                className={`${selectBoxOptions} ${customStyles}`}
            >
                {children}
                {!children &&
                    options.map((option) => (
                        <AdbListBoxOption option={option} selectedOption={selectedOption}/>
                    ))
                }
            </ListboxOptions>
        </OpenCloseTransition>
    )

}

function AdbListBoxOption({option, selectedOption, children}) {
    return (
        <ListboxOption
            key={option.value}
            value={option}
            className={({ active, selected }) =>
                `${selectBoxOption} ${
                active
                    ? "tw-rounded-md hover:tw-bg-brand-blue-light"
                    : ""
                } ${selected || selectedOption.value === option.value ? "tw-rounded-md tw-bg-brand-blue-light" : ""}`
            }
            >
            {children || option.label || option.value}
        </ListboxOption>
    )
}


export default function AdbSelectBox({value, onChange, button, buttonProps, content, optionsProps}) {
    return (
        <AdbListBox value={value} onChange={onChange}>
            <AdbListBoxButton {...buttonProps}>
                {button}
            </AdbListBoxButton>
            <AdbListBoxOptions {...optionsProps}>
                {content}
            </AdbListBoxOptions>
        </AdbListBox>
    )
}
 
export {
    AdbListBox,
    AdbListBoxButton,
    AdbListBoxOptions,
    AdbListBoxOption
}