import React, { Fragment } from "react";

import { Transition } from "@headlessui/react";

/*
  Wrapper component which provides headless-ui transition for the child component
  for Open and Close action.
*/
function OpenCloseTransition({ children }) {
  return (
    <Transition
      as={Fragment}
      enter="tw-transition tw-ease-out tw-duration-100"
      enterFrom={`tw-transform tw-opacity-0 tw-scale-50`}
      enterTo={`tw-transform tw-opacity-100 tw-scale-100`}
      leave="tw-transition tw-ease-in tw-duration-100"
      leaveFrom="tw-transform tw-opacity-100 tw-scale-100"
      leaveTo={`tw-transform tw-opacity-0 tw-scale-50`}
    >
      {children}
    </Transition>
  );
}

export default OpenCloseTransition;