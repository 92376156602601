import {
  cloneElement,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";

import { NavLink, useParams } from "react-router-dom";
import Highcharts from "highcharts/highstock";
import {
  Disclosure,
  DisclosureButton,
  DisclosurePanel,
} from "@headlessui/react";

import {
  AdbChevronFilled,
  AdbLayoutLeft,
  AdbLogo,
  AdbLogoFull,
} from "../../../adb-icons/AdbIcons";

import { useAuth } from "../../../context/AuthContext";
import { useNavContext } from "../../../context/NavContext";
import { isSharedEnv } from "../../../service";
import navigation from "../../../menu-items";

import {
  iconClassesWithHover,
  iconClassesGroupHover,
} from "../../../utils/commonTailwindClasses";

import {
  ALLOWED_LEFT_NAV_GROUPS,
  ALLOWED_LEFT_NAV_ITEMS,
} from "../../../constants/sharedEntity";
import { NAVBAR_AND_HEADER_DIMENSIONS } from "../../../store/constant";

function NavigationBadge({ variant, text }) {
  return (
    <div
      className={`${variant === "info" ? "tw-from-purple-500 tw-to-teal-400" : "tw-from-orange-500 tw-to-yellow-500"} tw-mx-2 tw-inline-flex tw-items-center tw-justify-center tw-rounded-full tw-bg-gradient-to-r tw-px-2.5`}
    >
      <span className="tw-py-0.5 tw-text-[10px] tw-normal-case tw-leading-4 tw-text-white">
        {text}
      </span>
    </div>
  );
}

function NavigationItem({
  navItem,
  navGroup,
  isActiveLink = false,
  showIcon = true,
  isNavOpen = true,
  styles,
}) {
  let navContent;

  if (navItem.external) {
    navContent = (
      <a href={navItem.url} target="_blank" rel="noopener noreferrer">
        {cloneElement(navGroup.icon || navItem.icon, {
          className: `${isActiveLink ? "!tw-stroke-brand-blue" : ""} ${iconClassesGroupHover} min-icon-dimensions`,
        })}
        {isNavOpen && (
          <span className={`${showIcon ? "tw-mt-px" : ""}`}>
            {navItem.title}
          </span>
        )}
      </a>
    );
  } else if (navItem.startChat) {
    const openChatWidget = () => {
      if (window?.HubSpotConversations) {
        window.HubSpotConversations.widget.load();
        window.HubSpotConversations.widget.open();
      }
    };

    navContent = (
      <a href="#" rel="noopener noreferrer" onClick={openChatWidget}>
        {cloneElement(navGroup.icon || navItem.icon, {
          className: `${isActiveLink ? "!tw-stroke-brand-blue" : ""} ${iconClassesGroupHover} min-icon-dimensions`,
        })}
        {isNavOpen && (
          <span className={`${showIcon ? "tw-mt-px" : ""}`}>
            {navItem.title}
          </span>
        )}
      </a>
    );
  } else {
    navContent = (
      <NavLink to={navItem.url}>
        {showIcon &&
          cloneElement(navGroup.icon || navItem.icon, {
            className: `${isActiveLink ? "!tw-stroke-brand-blue" : ""} ${iconClassesGroupHover} min-icon-dimensions`,
          })}
        {isNavOpen && (
          <>
            <span className={`${showIcon ? "tw-mt-px" : ""}`}>
              {navItem.title}
            </span>
            {navItem.badge && (
              <NavigationBadge
                variant={navItem.badge.variant}
                text={navItem.badge.text}
              />
            )}
          </>
        )}
      </NavLink>
    );
  }

  return cloneElement(navContent, { className: styles });
}

function LeftNavigation({ selectedAccountDetails }) {
  const [isLeftNavExpanded, setIsLeftNavExpanded] = useState();
  const [disclosureState, setDisclosureState] = useState(null);
  const disclosureRefs = useRef({});
  const navigationItemsRef = useRef();
  const { isNavLocked, setIsNavLocked } = useNavContext();
  const { organization } = useAuth();
  const { sharedEntityId } = useParams();

  let navItems;

  useLayoutEffect(
    function () {
      localStorage.setItem("navigation-locked", isNavLocked);

      if (isNavLocked) {
        document
          .querySelector(".custom-main-container")
          ?.classList?.add("left-nav-locked");
        document.querySelector(".main-header")?.classList?.add("left-nav-locked");
      } else {
        document
          .querySelector(".custom-main-container")
          ?.classList?.remove("left-nav-locked");
        document
          .querySelector(".main-header")
          ?.classList?.remove("left-nav-locked");
      }

      setTimeout(() => {
        for (var i = 0; i < Highcharts.charts.length; i++) {
          if (Highcharts.charts[i] !== undefined) {
            Highcharts.charts[i].reflow();
          }
        }
      }, 500);

      setIsLeftNavExpanded(isNavLocked ? true : false);
    },
    [isNavLocked],
  );

  useEffect(function () {
    Object.values(disclosureRefs.current).forEach(({ element, isActive }) => {
      if (isActive && element && !element.hasAttribute('data-open')) {
        element.click();
      }
    });
  }, [selectedAccountDetails]);

  function handleCollapseExpand(collapse) {
    if (isLeftNavExpanded && isNavLocked) return;

    setIsLeftNavExpanded(collapse ? false : true);
    navigationItemsRef.current.scrollTop = 0;
  }

  function handleLockUnlockNavigation() {
    setIsNavLocked(isNavLocked ? false : true);
  }

  function handleDisclosureChange(i, j) {
    setDisclosureState(`${i}${j}`); // close all of them
  }

  function isBasePathMatch(basePath, fullPath) {
    return fullPath.startsWith(basePath);
  } 

  navItems = navigation.items.filter((item) => {
    // if (item.id === "amc" && selectedAccountDetails?.amc_instance == null) {
    //   return false;
    // }

    if (item.id === "dsp" && selectedAccountDetails?.dsp_advertiser == null) {
      return false;
    }

    return true;
  });

  if (isSharedEnv) {
    const _id = sharedEntityId;

    navItems = navigation.items.filter((item) =>
      ALLOWED_LEFT_NAV_GROUPS.has(item.id),
    );

    navItems[0].children = navigation.items[0].children
      .filter((ch) => ALLOWED_LEFT_NAV_ITEMS.has(ch.id))
      .map((ch) => {
        if (_id) ch["url"] = `/s/${_id}${ch["url"]}`;
        return ch;
      });
  }

  return (
    <div
      className={`${isLeftNavExpanded ? `${!isNavLocked ? "tw-shadow-lg tw-shadow-gray-300" : ""}` : ""} tw-fixed tw-z-[1029] tw-flex tw-h-screen tw-flex-col tw-items-center tw-border-r tw-border-gray-200 tw-bg-white tw-transition-all tw-duration-200 tw-ease-in-out`}
      onMouseEnter={() => handleCollapseExpand()}
      onMouseLeave={() => handleCollapseExpand(true)}
      style={{width: isLeftNavExpanded ? `${NAVBAR_AND_HEADER_DIMENSIONS.EXPANDED_NAV_WIDTH}px` : `${NAVBAR_AND_HEADER_DIMENSIONS.COLLAPSED_NAV_WIDTH}px`}}
    >
      <div
        className={`${isLeftNavExpanded ? "tw-px-4 tw-justify-between" : "tw-justify-start tw-pl-[10px] tw-pr-[5px]"} tw-flex tw-items-center tw-w-full tw-whitespace-nowrap tw-overflow-hidden`}
        style={{ minHeight: `${NAVBAR_AND_HEADER_DIMENSIONS.HEADER_HEIGHT}px` }}
      >
        {!isLeftNavExpanded && (
          <>
            {isSharedEnv && organization.logo?.small ? (
                <img
                  src={`${organization.logo_prefix}${organization.logo.small}`}
                  className="tw-h-7 tw-w-7"  
                />
              ) : (
                <AdbLogo className="tw-h-5 tw-w-7" />
              )
            }
          </>
        )}
        {isLeftNavExpanded && (
          <>
            {isSharedEnv && organization.logo?.large ? (
              <img
                src={`${organization.logo_prefix}${organization.logo.large}`}
                className="tw-h-4 tw-w-20"
              />
            ) : (
              <AdbLogoFull className="tw-min-h-5 tw-min-w-24 tw-h-5 tw-w-24" />
            )}
            <AdbLayoutLeft
              className={`${iconClassesWithHover} min-icon-dimensions`}
              onClick={handleLockUnlockNavigation}
            />
          </>
        )}
      </div>
      <nav
        className={`${isLeftNavExpanded ? "tw-overflow-y-auto" : ""} tw-mt-[22px] tw-flex tw-w-full tw-flex-1 tw-flex-col tw-px-4 tw-pb-3`}
        ref={navigationItemsRef}
      >
        <ul
          role="list"
          className="tw-flex tw-flex-1 tw-flex-col tw-gap-y-[22px]"
        >
          {navItems.map((navGroup, i) => {
            return (
              <li>
                <div
                  className={`${!isLeftNavExpanded ? "tw-ml-[5px] tw-justify-start tw-text-brand-icon-gray" : ""} tw-flex tw-items-center tw-text-small-tags tw-font-medium tw-uppercase tw-leading-6 tw-text-gray-400 tw-whitespace-nowrap tw-overflow-hidden`}
                >
                  {!isLeftNavExpanded && <>&bull;</>}
                  {isLeftNavExpanded && (
                    <>
                      {navGroup.title}
                      {navGroup.badge && (
                        <NavigationBadge
                          variant={navGroup.badge.variant}
                          text={navGroup.badge.text}
                        />
                      )}
                    </>
                  )}
                </div>
                <ul
                  role="list"
                  className="tw-flex tw-flex-col tw-gap-[6px] tw--mx-2 tw-mt-[9px]"
                >
                  {navGroup.children.map((navItem, j) => {
                    let isActiveLink;

                    if (navItem.children) {
                      isActiveLink = navItem.children.some(
                        (subNavItem) => subNavItem.url && isBasePathMatch(subNavItem.url, location.pathname)
                      );
                    } else {
                      isActiveLink = navItem.url && isBasePathMatch(navItem.url, location.pathname);
                    }

                    return (
                      <li key={navItem.id}>
                        {!navItem.children ? (
                          <NavigationItem
                            navItem={navItem}
                            navGroup={navGroup}
                            isActiveLink={isActiveLink}
                            isNavOpen={isLeftNavExpanded}
                            styles={`
                                  ${
                                    isActiveLink
                                      ? "!tw-text-brand-blue"
                                      : "tw-text-brand-text-gray hover:tw-bg-brand-blue-light hover:tw-text-brand-blue"
                                  }
                                  ${!isLeftNavExpanded ? "" : ""}
                                  tw-group tw-flex tw-items-center tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-xs tw-leading-none tw-font-medium tw-whitespace-nowrap tw-overflow-hidden`}
                          />
                        ) : (
                          <Disclosure as="div">
                            <DisclosureButton
                              className={` ${
                                isActiveLink
                                  ? "!tw-text-brand-blue"
                                  : "tw-text-brand-text-gray hover:tw-text-brand-blue"
                              }
                                  ${!isLeftNavExpanded ? "" : ""}
                                  tw-group tw-flex tw-w-full tw-items-center tw-gap-x-3 tw-rounded-md tw-p-2 tw-text-left tw-text-xs tw-leading-none tw-font-medium focus:tw-outline-none tw-whitespace-nowrap tw-overflow-hidden`}
                              onClick={() => handleDisclosureChange(i, j)}
                              ref={(el) =>
                                (disclosureRefs.current[`${i}${j}`] = {
                                  element: el,
                                  isActive: isActiveLink,
                                })
                              }
                            >
                              {cloneElement(navGroup.icon || navItem.icon, {
                                className: `${isActiveLink ? "!tw-stroke-brand-blue" : ""} ${iconClassesGroupHover} min-icon-dimensions`,
                              })}
                              <>
                                <span
                                  className={`${isLeftNavExpanded ? "tw-visible" : "tw-invisible"} tw-mt-px`}
                                >
                                  {navItem.title}
                                </span>
                                <AdbChevronFilled
                                  aria-hidden="true"
                                  className={`${isLeftNavExpanded ? "tw-visible" : "tw-invisible"} ${isActiveLink ? "!tw-fill-brand-blue" : ""} tw-ml-auto tw-h-4 tw-w-4 tw-shrink-0 tw-fill-brand-icon-gray tw-text-gray-400 tw-opacity-0 tw-transition-opacity tw-duration-0 tw-delay-150 [&:nth-of-type(n)]:tw-opacity-100 group-hover:tw-cursor-pointer group-hover:tw-fill-brand-blue group-data-[open]:group-data-[open]:tw-rotate-90 group-data-[open]:tw-text-gray-500`}
                                />
                              </>
                            </DisclosureButton>
                            <DisclosurePanel
                              as="ul"
                              className="tw-pb-2 tw-pl-4 tw-pr-2 tw-pt-1.5"
                            >
                              {({ close }) =>
                                disclosureState === `${i}${j}` ? (
                                  <>
                                    {navItem.children.map((subNavItem) => (
                                      <li
                                        key={subNavItem.name}
                                        className={`${isLeftNavExpanded && location.pathname === subNavItem.url ? "!tw-border-brand-blue" : ""} tw-cursor-pointer tw-border-l-2 tw-border-brand-very-light-gray tw-py-0.5 tw-pl-[11px]`}
                                      >
                                        <NavigationItem
                                          navItem={subNavItem}
                                          showIcon={false}
                                          isActiveLink={isActiveLink}
                                          styles={(isActive) =>
                                            `${isActive ? "!tw-text-brand-blue tw-bg-brand-blue-light" : ""}
                                                ${isLeftNavExpanded ? "tw-visible" : "tw-invisible"}
                                                tw-flex tw-items-center tw-rounded-md tw-pr-2 tw-pl-2 tw-py-1.5 tw-text-xs tw-leading-5 tw-text-brand-text-gray tw-whitespace-nowrap tw-overflow-hidden hover:tw-bg-brand-blue-light hover:tw-text-brand-blue
                                                `
                                          }
                                        />
                                      </li>
                                    ))}
                                  </>
                                ) : (
                                  close()
                                )
                              }
                            </DisclosurePanel>
                          </Disclosure>
                        )}
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          })}
        </ul>
      </nav>
    </div>
  );
}

export default LeftNavigation;