import React, { useContext, createContext, useState, useEffect } from "react";
import { getAuthToken, getOrganization, getUser, isSharedEnv } from "../service";
import { notificationContext } from "./NotificationContext";
import ReactGA from 'react-ga4';
import moment from "moment";
import { useHistory } from "react-router-dom";
import { getEncodedRedirectParam } from "../utils/locationUtils";
import { clearConflictingKeysForSharedEnv } from "../utils/localStorageUtils";
import { associateUserWithGroup, identifyUser } from "./UsageTrackingContext";
import { isDemoMode } from "../Util";

const authContext = createContext();

function useAuth() {
    return useContext(authContext);
}

function ProvideAuth({ children }) {
    const auth = useProvideAuth();
    return (
      <authContext.Provider value={auth}>
        {children}
      </authContext.Provider>
    );
}

const signout = () => {
    // TODO: Test with adbrew user
    if (localStorage.getItem("credentials")) {
        localStorage.removeItem("credentials");
    }
    
    const signinURL = isSharedEnv ? '/guest/auth/signin' : '/auth/signin'
    window.location.href = `${signinURL}?${getEncodedRedirectParam()}`;
}

function useProvideAuth() {

    const {accessToken, refreshToken} = JSON.parse(localStorage.getItem("credentials") || "{}");
    const [authenticated, setAuthenticated] = useState((accessToken && refreshToken) ? true : false);
    const [isGuestUser, setIsGuestUser] = useState(isSharedEnv);
    const [user, setUser] = useState({})
    const [organization, setOrganization] = useState({})
    const { setNotification }  = useContext(notificationContext);
    const [justLoggedIn, setJustLoggedIn] = useState(false)
    const history = useHistory();

    useEffect(() => {
        if (justLoggedIn && !isSharedEnv && user?.name && user?.email && organization && organization.name) {
            identifyUser(user._id, user.name, user.email, organization.name, 'login', organization._id)
            try {
                associateUserWithGroup(user._id, organization._id, {
                    name: organization.name + " (Org)",
                    plan: organization.plan,
                    createdAt: organization.created,
                    status: organization.status,
                    is_active: organization.is_active,
                    valid_until: organization.valid_until
                })
            } catch (e) {
                console.log(e)
            }
        }
    }, [justLoggedIn, user, organization])

    const fetchData = async () => {
        try {
            const {organization} = await getOrganization({});
            const {user} = await getUser();
            setUser(user);
            setOrganization(organization);
            if(!isSharedEnv){
                ReactGA.set({
                    'user_properties': {
                      'org_name': organization.name,
                    }
                  });
                function onConversationsAPIReady() {
                  var _hsq = window._hsq = window._hsq || [];
                   _hsq.push(["identify",{
                      email: user.email
                    }]);
                   _hsq.push(['trackPageView']);
                }
                
                if (window.HubSpotConversations) {
                     onConversationsAPIReady();
                } else {
                    window.hsConversationsOnReady = [onConversationsAPIReady];
                }
            }
        } catch (e) {
            console.log({e})
            setNotification("Error", "Something went wrong, please try again.", "error");
        }
    };

    const refreshUserData = async () => {
        try {
            const {user} = await getUser();
            setUser(user);
        } catch (e) {
            console.log({e})
        }
    }

    const signin = async (username, password) => {
        if (username.trim().length === 0 || password.trim().length === 0) {
            throw Error();
        }
        try {
            const creds = await getAuthToken({ email: username, password });
            if(!creds.two_fa_enabled){
                localStorage.setItem('credentials', JSON.stringify({accessToken: creds.access, refreshToken: creds.refresh}));
                setAuthenticated(true);
                setJustLoggedIn(true);
            } else {
                const searchParams = new URLSearchParams(window.location.search);
                const redirectParam = searchParams.has('redirect') ? `?redirect=${searchParams.get('redirect')}` : '';
                history.push({
                    pathname: '/auth/signin-otp',
                    search: redirectParam,
                    state: { username, password }
                });
            }
        } catch (err) {
            throw Error(err);
        }
    }

    const requestOtpForGuestUser = async (email) => {
        if (email.trim().length === 0)
            throw Error();
        try {
            await getAuthToken({ email, password: 'null' });
        } catch (err) {
            throw Error(err);
        }
    }

    const guestSignIn = async (email, otp, entityId) => {
        if (email.trim().length === 0)
            throw Error();
        try {
            const creds = await getAuthToken({ email, password: 'null', otp, entity_id: entityId })
            if(creds){
                // To avoid sending outdated info in request header from localstorage
                clearConflictingKeysForSharedEnv()

                localStorage.setItem('credentials', JSON.stringify({accessToken: creds.access, refreshToken: creds.refresh}));
                setIsGuestUser(true)
                setJustLoggedIn(true);
                setAuthenticated(true);
            }
        } catch (err) {
            throw Error(err);
        }
    }

    const _signout = () => {
        console.log("SIGNING OUT");
        signout()
        // setAuthenticated(false);
    }

    const isUserAdmin = (user?.orgs || []).find(({id}) => id === organization?._id)?.role === "admin"
    
    const getValidDays = () => {
        if (organization.valid_until) {
            return moment(organization.valid_until).diff(moment().startOf('day'), 'days')
        }
    }

    const getDemoMeetingLink = () => {
        return `https://meetings-eu1.hubspot.com/meetings/akshayatadbrew/adbrew-demo?email=${user?.email}&firstName=${user?.name}`
    }
        
    useEffect(() => {
        if (authenticated)
            fetchData();
    }, [authenticated]);

    useEffect(() => {
        try {
            let query = window.location.search.substring(1)
            console.log({query})
            let params = new URLSearchParams(query);
            if (params.has('masquerade_id')) {
                localStorage.setItem('mid', params.get('masquerade_id'));
                window.location = '/profile'
            }
            if (params.has('account_id') && params.has('account_name')) {
                localStorage.setItem('account', JSON.stringify({ id: params.get('account_id'), account_name: params.get('account_name')}));
                window.location = '/profile'
            }
            if (params.has('masquerade_route')) {
                window.location = `/${params.get('masquerade_route')}`
            }
        } catch (e) {
            console.log(e)
        }
    }, [])

    const getUserSafe = (u) => {
        if (u && isDemoMode()) {
            u.name = "John Doe"
            u.email = "johndoe@adbrew.io"
        }
        return u;
    }

    const getOrganizationSafe = (o) => {
        if (o && isDemoMode()) {
            o.name = "Demo store"
        }
        return o;
    }

    return {
        signin,
        signout,
        isGuestUser,
        authenticated,
        setAuthenticated,
        justLoggedIn,
        setJustLoggedIn,
        user: getUserSafe(user),
        refreshUserData,
        organization: getOrganizationSafe(organization),
        signout: _signout,
        isUserAdmin,
        validDays: getValidDays(),
        getDemoMeetingLink,
        requestOtpForGuestUser,
        guestSignIn
    }
}


export {
    useAuth,
    ProvideAuth,
    signout
}
